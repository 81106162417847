import _ from 'lodash';
export function validateEmail(elementValue) {
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
}

export const DEFAULT_FIT = {
  northeast: {
    longitude: -85.3741195501643,
    latitude: 38.3319910165582,
  },
  southwest: {
    longitude: -85.9471156948091,
    latitude: 38.000944082558,
  },
};

export const getSearchParamHeaders = (query) => {
  if (!query) return {};
  let price = [];
  const p = query.get('price') ? query.get('price').split(',') : [];
  if (p.indexOf('1') > -1) price.push('$');
  if (p.indexOf('2') > -1) price.push('$$');

  if (p.indexOf('3') > -1) price.push('$$$');

  if (p.indexOf('4') > -1) price.push('$$$$');
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      northeast: {
        longitude: query.get('maxLng') || DEFAULT_FIT.northeast.longitude,
        latitude: query.get('maxLat') || DEFAULT_FIT.northeast.latitude,
      },
      southwest: {
        longitude: query.get('minLng') || DEFAULT_FIT.southwest.longitude,
        latitude: query.get('minLat') || DEFAULT_FIT.southwest.latitude,
      },
      sort: {
        field:
          query.has('sort') &&
          ['rating', 'distance'].indexOf(query.get('sort')) > -1
            ? query.get('sort')
            : null,
        order: query.get('order'),
      },
      filters: {
        price,
        delivery: query.get('delivery') === 'true',
        carryout: query.get('carryout') === 'true',
        categories:
          query.has('tags') && query.get('tags') !== ''
            ? query
                .get('tags')
                .split(',')
                .map((item) => parseInt(item))
            : [],
      },
    }),
  };
};
