import React from 'react';
import cx from 'classnames';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';

export default (props) => {
  const btnHover =
    'bg-transparent text-gray-900 transform transition-all duration-150 ease-out-cubic hover:bg-gray-100 hover:text-gray-900 ';
  const btnClass =
    'text-white-700 font-semibold py-2 px-4 border border-white-500 rounded';
  const btnDisabled = 'text-gray-500 cursor-not-allowed bg-gray-100';
  return (
    <div className="w-full my-8  items-center justify-between flex">
      <button
        onClick={props.onClickPrev}
        disabled={!props.hasPrev}
        className={cx({
          [btnClass]: true,
          [btnHover]: props.hasPrev,
          [btnDisabled]: !props.hasPrev,
        })}
      >
        <ChevronLeft />
      </button>
      <p className="text-white-700 text-size-sm">
        Page {props.current} of {props.total}
      </p>
      <button
        disabled={!props.hasNext}
        onClick={props.onClickNext}
        className={cx({
          [btnClass]: true,
          [btnHover]: props.hasNext,
          [btnDisabled]: !props.hasNext,
        })}
      >
        <ChevronRight />
      </button>
    </div>
  );
};
