import React from 'react';

const Label = (props) => {
  return (
    <label
      className={
        props.className + 'sm:text-sm sm:leading-5 text-sm font-gray-900 mb-2'
      }
    >
      {props.name || props.children}
    </label>
  );
};

export default Label;
