import React, {
  Suspense,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { Helmet } from 'react-helmet';

import Map from 'components/map';

import BusinessCard from 'components/business-card';
import { useData, useMyCollections } from 'data';
import Pagination from 'components/pagination';
import cx from 'classnames';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import './styles.css';
import { withDevice } from 'hoc';
import Filters from './filters';
import { getSearchParamHeaders } from 'utils';
import { SearchInput, Modal, RowSpacer } from 'components';
import { ReactComponent as Illustration } from 'assets/illustrations/food.svg';
import { ReactComponent as FoodSadIllustration } from 'assets/illustrations/food-sad.svg';

const Businesses = (props) => {
  const stores = useData(
    '/stores?page=' + (props.query.has('p') ? props.query.get('p') : 0),
    getSearchParamHeaders(props.query),
  );
  const onClickNext = () => {
    const url = new URLSearchParams(props.query);
    url.set('p', stores.current_page + 1);
    props.push({ location: '/s', search: url.toString() });
    window.scrollTo(0, 0);
  };

  const onClickPrev = () => {
    const url = new URLSearchParams(props.query);
    url.set('p', stores.current_page - 1);
    props.push({ location: '/s', search: url.toString() });
    window.scrollTo(0, 0);
  };
  if (!stores.data || stores.data.length === 0) {
    return (
      <div className="block flex flex-col items-center text-center">
        <div className="p-12" />
        <FoodSadIllustration />
        <p className="text-center pt-8 text-gray-900 text-lg font-semibold">
          Looks like we aren't quite there yet
        </p>
        <Link
          to="/s"
          className="mx-auto mt-4 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
        >
          Reset search
        </Link>
      </div>
    );
  }
  const changeSort = (e) => {
    const { value } = e.target;
    const url = new URLSearchParams(props.query);
    url.set('sort', value);
    url.set('order', value ? (value === 'distance' ? 'asc' : 'desc') : '');
    props.push({ location: '/s', search: url.toString() });
    window.scrollTo(0, 0);
  };

  const url = new URLSearchParams(props.query);
  return (
    <>
      <div className="p-4 sm:p-6 w-full" />
      {props.isMedium && <SearchInput />}
      <p className="py-2 mt-8">{stores.total} results</p>
      <div className="flex-shrink-0 md:flex-row flex-col flex md:items-center justify-between">
        <h1 className="font-bold text-2xl">
          Search {stores.city ? ' in ' : ' '} {stores.city}
        </h1>
        <div className="text-sm text-gray-600 flex flex-row items-center">
          Sort
          <select
            className="appearance-none ml-2 text-gray-800 bg-transparent h-10 cursor-pointer"
            value={url.get('sort')}
            onChange={changeSort}
          >
            <option value="default">Recommended</option>
            <option value="distance">Distance</option>
            <option value="rating">Rating</option>
          </select>
        </div>
      </div>
      <Filters push={props.push} query={props.query} />
      {stores.data.map((business, i) => (
        <BusinessCard
          push={props.push}
          query={props.query}
          isFirst={i === 0}
          isMedium={props.isMedium}
          selected={business.id === props.selected}
          price={business.price}
          key={business.id}
          id={business.id}
          pageId={i + 30 * (stores.current_page - 1) + 1}
          imageUrl={business.image_url}
          street={business.street}
          categories={business.categories_string}
          name={business.name}
          rating={business.rating}
          reviewCount={business.review_count}
          link={`/business/${business.id}`}
          auth={props.auth}
        />
      ))}
      <Pagination
        hasPrev={stores.prev_page_url}
        hasNext={stores.next_page_url}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        current={stores.current_page}
        total={stores.last_page}
      />
    </>
  );
};

export default Businesses;
