import React, { Suspense, useContext } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  Link,
} from 'react-router-dom';

import './App.css';
import About from './routes/about';
import Business from './routes/business';
import Claim from './routes/claim-business';
import Collection from './routes/collection';
import Collections from './routes/collections';
import Cookies from 'js-cookie';
import Home from './routes/home';
import Login from './routes/login';
import Navigation from './components/navigation';
import NoMatch from './routes/404';
import Privacy from './routes/policy';
import Profile from './routes/profile';
import Search from './routes/search';
import UserContext, { UserContextProvider } from 'context/UserContext';
import Edit from './routes/edit';

import './index.css';
const withSuspense = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <React.Suspense fallback={<WrappedComponent {...this.props} loading />}>
          <WrappedComponent {...this.props} />
        </React.Suspense>
      );
    }
  };
};

const PrivateRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? <Component auth={auth} {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const PublicRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => <Component auth={auth} {...props} />} />
  );
};
function App() {
  const [auth, setAuth] = React.useState(Cookies.get('access_token'));

  return (
    <Router>
      <UserContextProvider>
        <Navigation auth={auth} />
        <Switch>
          <Route component={Home} exact path="/"></Route>
          <PublicRoute
            auth={auth}
            component={Search}
            exact
            path="/s"
          ></PublicRoute>
          <Route
            component={(props) => <Login {...props} setAuth={setAuth} />}
            exact
            path="/(login|signup)"
          ></Route>
          <Route
            component={withSuspense(Business)}
            exact
            path="/business/:id"
          />
          <Route
            component={(props) => <Claim {...props} auth={auth} />}
            exact
            path="/claim-business/:id"
          />
          <PublicRoute
            auth={auth}
            component={Privacy}
            exact
            path="/privacy-policy"
          ></PublicRoute>
          <PrivateRoute
            auth={auth}
            component={Edit}
            exact
            path="/business/:id/:tab?"
          ></PrivateRoute>
          <PublicRoute
            auth={auth}
            component={About}
            exact
            path="/about-us"
          ></PublicRoute>
          <PrivateRoute
            auth={auth}
            component={Collections}
            exact
            path="/collections"
          />
          <PrivateRoute
            auth={auth}
            component={Collection}
            exact
            path="/collection/:id"
          />
          <PrivateRoute auth={auth} component={Profile} exact path="/profile" />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </UserContextProvider>
    </Router>
  );
}

export default App;
