import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Close } from 'assets/icons/x.svg';

import cx from 'classnames';

export default class PhotoSlider extends React.PureComponent {
  state = { selected: 0 };

  setPrev = () =>
    this.setState({
      selected:
        this.state.selected === 0
          ? this.props.photos.length - 1
          : this.state.selected - 1,
    });

  setNext = () => {
    const next =
      this.state.selected === this.props.photos.length - 1
        ? 0
        : this.state.selected + 1;
    this.setState({ selected: next });
  };

  keyDown = (e) => {
    switch (e.code) {
      case 'ArrowLeft':
        this.setPrev();
        break;
      case 'ArrowRight':
        this.setNext();
        break;
      case 'Escape':
        this.props.onClose();
        break;
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.selected > -1 && prevProps.selected === -1) {
      document.addEventListener('keydown', this.keyDown);
      this.setState({ selected: this.props.selected });
    }

    if (this.props.selected === -1) {
      document.removeEventListener('keydown', this.keyDown);
    }
  }
  render() {
    if (this.props.selected === -1) return null;

    const btnHover =
      'bg-transparent text-gray-900 transform transition-all duration-150 ease-out-cubic md:hover:bg-gray-100 md:hover:text-gray-900 ';
    const btnClass =
      'text-white-700 font-semibold py-2 px-4 md:border  border-white-500 rounded';
    const btnDisabled = 'text-gray-500 cursor-not-allowed bg-gray-100';
    const { props, setNext, setPrev } = this;
    const elm = (
      <>
        <div
          style={{ zIndex: 51 }}
          className="md:p-10 sm:px-24 px-12 flex align-center justify-center fixed top-0 bottom-0 left-0 right-0 bg-white"
        >
          <button
            onClick={props.onClose}
            style={{
              top: '2rem',
            }}
            className={cx({
              [btnClass]: true,
              [btnHover]: true,
              ['fixed right-0 md:z-0 z-10 md:mx-10']: true,
            })}
          >
            <Close />
          </button>
          <button
            onClick={setPrev}
            style={{ top: '50%', transform: 'translateY(-50%)' }}
            className={cx({
              [btnClass]: true,
              [btnHover]: true,
              ['fixed md:h-auto h-full md:mx-10 mx-0 left-0']: true,
            })}
          >
            <ChevronLeft />
          </button>
          <img
            className="object-contain"
            src={props.photos[this.state.selected]}
          />
          <button
            style={{ top: '50%', transform: 'translateY(-50%)' }}
            onClick={setNext}
            className={cx({
              [btnClass]: true,
              [btnHover]: true,
              ['fixed md:h-auto h-full md:mx-10 mx-0 right-0']: true,
            })}
          >
            <ChevronRight />
          </button>
        </div>
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-white" />
      </>
    );
    return ReactDOM.createPortal(elm, document.body);
  }
}
