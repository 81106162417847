import React from 'react';
import classNames from 'classnames';

export const RowSpacer = (props) => {
  return (
    <div
      className={classNames('flex-shrink-0 w-full', {
        'h-1': props.size === 'xs',
        'h-2': props.size === 'sm',
        'h-4': props.size === 'md',
        'h-8': props.size === 'lg',
        'h-12': props.size === 'xl',
        'h-24': props.size === '2xl',
      })}
    />
  );
};
