import React from 'react';
import { Footer } from 'components';
export default () => (
  <>
    <div className="flex flex-col bg-secondary h-full flex align-center justify-center">
      <div className="flex-1 relative z-10 container h-full mx-auto flex align-center justify-center">
        <div
          style={{ maxWidth: '40rem' }}
          className="md:mx-0 mx-2 w-full bg-white  rounded-md px-12 m-auto flex flex-col align-center py-12"
        >
          <h1 className="font-semibold text-2xl">Privacy Policy</h1>
          <p className="mt-4 ">
            Privacy Policy Your privacy is important to us. It is Loucal's
            policy to respect your privacy regarding any information we may
            collect from you across our website, http://loucal.us, and other
            sites we own and operate. We only ask for personal information when
            we truly need it to provide a service to you. We collect it by fair
            and lawful means, with your knowledge and consent. We also let you
            know why we’re collecting it and how it will be used. We only retain
            collected information for as long as necessary to provide you with
            your requested service. What data we store, we’ll protect within
            commercially acceptable means to prevent loss and theft, as well as
            unauthorized access, disclosure, copying, use or modification. We
            don’t share any personally identifying information publicly or with
            third-parties, except when required to by law. Our website may link
            to external sites that are not operated by us. Please be aware that
            we have no control over the content and practices of these sites,
            and cannot accept responsibility or liability for their respective
            privacy policies. You are free to refuse our request for your
            personal information, with the understanding that we may be unable
            to provide you with some of your desired services. Your continued
            use of our website will be regarded as acceptance of our practices
            around privacy and personal information. If you have any questions
            about how we handle user data and personal information, feel free to
            contact us. This policy is effective as of 19 April 2020.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </>
);
