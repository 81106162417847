import React from 'react';
import classNames from 'classnames';

const Alert = (props) => {
  return (
    <div
      className={classNames(
        props.className +
          ' bg-gray-200 text-gray-800  px-4 py-2 w-full font-bold rounded-lg',
        {
          ['text-red-700 bg-red-200']: props.type === 'error',
          ['text-green-700 bg-green-200']: props.type === 'success',
        },
      )}
    >
      {props.children}
    </div>
  );
};

export default Alert;
