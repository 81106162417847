import React, {
  Suspense,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  PureComponent,
} from 'react';
import Map from 'components/map';
import BusinessCard from 'components/business-card';
import { useData } from 'data';
import Pagination from 'components/pagination';
import cx from 'classnames';
import _ from 'lodash';
import { Marker, Popup } from 'react-mapbox-gl';
import { Link } from 'react-router-dom';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import { ReactComponent as Square } from 'assets/icons/square.svg';
import { ReactComponent as CheckSquare } from 'assets/icons/check-square.svg';
import Rating from 'components/rating';
import { withDevice } from 'hoc';
import { getSearchParamHeaders, DEFAULT_FIT } from 'utils';

import './styles.css';

const mapElementStyle = {
  zIndex: 101,
  boxShadow:
    'rgba(0, 0, 0, 0.2) 0px 6px 20px, rgba(0, 0, 0, 0.04) 0px 0px 0px 1px',
};

const Markers = (props) => {
  const stores = useData(
    '/stores?page=' + (props.query.has('p') ? props.query.get('p') : 0),
    getSearchParamHeaders(props.query),
  );

  if (!stores.data) return null;
  const MarkerElm = props.isMedium ? 'button' : Link;
  const PopupElm = props.isMedium ? Link : 'div';
  return stores.data.map((store, i) => (
    <div key={store.id}>
      {props.selected === store.id && (
        <Popup
          key={store.id + '-selected'}
          offset={[0, -40]}
          style={{
            zIndex: 100,
            width: '16rem',
          }}
          className="marker"
          coordinates={[store.longitude, store.latitude]}
        >
          <PopupElm
            to={`/business/${store.id}?${props.query.toString()}`}
            target="_blank"
          >
            <div className="w-full h-24 overflow-hidden">
              <img
                src={store.image_url}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="px-3 justify-center py-3 text-left flex flex-1 flex-col">
              <div className="text-lg font-semibold">{store.name}</div>
              <Rating className="mt-2 mb-1" rating={store.rating} />
              <div className="flex pt-1 flex-wrap">
                {store.categories_string.split('|').map((cat, i) => (
                  <div className="inline-flex" key={cat}>
                    {i > 0 && <p className="text-gray-700 mx-1">·</p>}
                    <p className="text-gray-700">{cat}</p>
                  </div>
                ))}
              </div>
            </div>
          </PopupElm>
        </Popup>
      )}
      <Marker
        key={store.id + '-marker'}
        style={{ zIndex: props.selected === store.id ? 100 : null }}
        anchor="bottom"
        coordinates={[store.longitude, store.latitude]}
      >
        <MarkerElm
          to={`/business/${store.id}?${props.query.toString()}`}
          onMouseOver={
            props.isMedium ? null : () => props.setSelected(store.id)
          }
          onClick={!props.isMedium ? null : () => props.setSelected(store.id)}
          className={cx({
            'bg-white text-primary-400': props.selected === store.id,
            'hover:bg-white text-white bg-primary-400 hover:text-primary-400':
              props.selected !== store.id,
            'transition-all duration-150 ease-out-cubic focus:outline-none transform -rotate-45 h-8 flex align-center justify-center rounded-br-full rounded-tr-full rounded-tl-full text-sm w-8 ': true,
          })}
        >
          <p className="font-bold transform rotate-45 md:-translate-x-1 md:translate-y-1">
            {i + 30 * (stores.current_page - 1) + 1}
          </p>
        </MarkerElm>
      </Marker>
    </div>
  ));
};

class BusinessesMap extends PureComponent {
  constructor(props) {
    super(props);
    const shouldMoveAsMap = localStorage.getItem('shouldMoveAsMap');
    this.state = {
      hasMoved: true,
      hasUpdatedBounds: false,
      shouldMoveAsMap: shouldMoveAsMap !== null ? shouldMoveAsMap === '1' : 1,
    };
  }

  toggleSearchMap = () => {
    const { props } = this;
    const url = new URLSearchParams(props.query.toString());
    let move = 1;
    if (this.state.shouldMoveAsMap) {
      move = 0;
    }
    this.setState({ shouldMoveAsMap: !!move });
    localStorage.setItem('shouldMoveAsMap', move);
  };

  getCenter = () => {
    if (!this.props.query.has('center')) return null;
    const center = this.props.query.get('center');

    return center.split(',').map(parseFloat);
  };

  getBounds = (props = this.props) => [
    [
      parseFloat(props.query.get('maxLng')),
      parseFloat(props.query.get('maxLat')),
    ],
    [
      parseFloat(props.query.get('minLng')),
      parseFloat(props.query.get('minLat')),
    ],
  ];

  hasBounds = (props = this.props) =>
    props.query.has('maxLng') &&
    props.query.has('maxLat') &&
    props.query.has('minLng') &&
    props.query.has('minLat');

  updateParams = (map = this.map) => {
    if (!map) return;
    const bounds = map.getBounds();
    const q = new URLSearchParams(this.props.query.toString());
    q.set('minLat', bounds._sw.lat);
    q.set('minLng', bounds._sw.lng);
    q.set('maxLat', bounds._ne.lat);
    q.set('maxLng', bounds._ne.lng);
    q.delete('center');
    this.props.push({ location: '/s', search: q.toString() });
    this.hasClickedRedo = false;
  };

  onMove = _.debounce((map) => {
    if (!this.state.hasMoved) {
      this.setState({ hasMoved: true, hasClickedRedo: false });
    }
    if (this.state.hasUpdatedBounds) {
      return this.setState({ hasUpdatedBounds: false });
    }
    this.map = map;
    if (
      this.props.push &&
      (this.state.shouldMoveAsMap || this.props.isMedium)
    ) {
      this.updateParams(map);
    }
  }, 200);

  render() {
    const { props } = this;

    const { hasMoved, hasUpdatedBounds } = this.state;

    const bounds = this.getBounds();
    return (
      <Map
        fitBounds={
          !hasUpdatedBounds && bounds[0][0] && bounds[1][1] ? bounds : null
        }
        onMove={this.onMove}
        onReverseGeocode={props.onReverseGeocode}
        movingMethod="jumpTo"
        interactive={true}
        center={this.getCenter()}
        onStyleLoad={(map) => {
          this.map = map;
        }}
        containerStyle={{
          position: this.props.isMedium ? 'relative' : 'fixed',
          boxShadow: 'inset 0 25px 50px 12px rgba(0, 0, 0, 0.25)',
          width: this.props.isMedium ? '100%' : 'calc(100vw - 40rem)',
          top: props.isMedium ? '0' : '73px',
          bottom: props.isMedium ? '0' : null,
          textAlign: 'center',
          height: this.props.isMedium ? '100%' : 'calc(100% - 4.5rem)',
        }}
      >
        {hasMoved && !this.state.shouldMoveAsMap ? (
          <button
            onClick={() => {
              this.setState({ hasClickedRedo: true, hasMoved: false });
              this.updateParams();
            }}
            style={mapElementStyle}
            className="justify-center rounded-lg hidden md:flex  relative mx-auto mt-6  bg-primary-500 hover:bg-primary-700 text-white font-md font-bold h-10 px-6 rounded"
          >
            Redo search
          </button>
        ) : (
          <button
            onClick={this.toggleSearchMap}
            style={mapElementStyle}
            className="justify-center rounded-lg hidden md:inline-flex  relative align-center mx-auto mt-6 bg-white hover:bg-gray-100 text-gray-900 text-md font-bold h-10 px-6 rounded"
          >
            {this.state.shouldMoveAsMap ? (
              <CheckSquare className="border-current w-5 h-5 mr-2" />
            ) : (
              <Square className="border-current w-5 h-5 mr-2" />
            )}
            Search as map moves
          </button>
        )}
        <a
          style={mapElementStyle}
          target="_blank"
          className="text-md absolute rounded-lg right-0 bottom-0 m-10 hidden md:inline-flex items-center font-semibold bg-white text-gray-900 hover:bg-gray-100 h-12 px-4 rounded"
          href="https://www.facebook.com/groups/loucal"
        >
          <img
            src={process.env.PUBLIC_URL + '/logos/facebook.png'}
            className="h-6 w-6 mr-3"
          />
          Join our Facebook group
        </a>
        <Suspense fallback={<div />}>
          <Markers
            isMedium={props.isMedium}
            setSelected={props.setSelected}
            selected={props.selected}
            query={props.query}
          />
        </Suspense>
        {props.children}
      </Map>
    );
  }
}

export default withDevice(BusinessesMap);
