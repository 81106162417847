import React, { Suspense } from 'react';
import cx from 'classnames';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import Map from 'components/map';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { useData, useYelp } from 'data';
import PhotoSlider from 'components/photo-slider';
import _ from 'lodash';
import moment from 'moment';

const Nav = (props) => {
  const [visible, setVisible] = React.useState(false);
  let timeout = false;
  const cb = () => {
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
    timeout = window.requestAnimationFrame(() => {
      if (window.pageYOffset > 300 && !visible) {
        setVisible(true);
      } else if (window.pageYOffset < 300) {
        setVisible(false);
      }
    });
  };
  React.useEffect(() => {
    // Check to see if nav is needed on mount
    if (window.pageYOffset > 300 && !visible) {
      setVisible(true);
    }
    window.addEventListener('scroll', cb);
    return () => {
      window.removeEventListener('scroll', cb);
    };
  }, []);
  const query = localStorage.getItem('query');

  return (
    <div
      style={{ zIndex: 51 }}
      className={
        cx({
          'translate-y-0': visible,
        }) +
        'transform transition-all duration-300 ease-out-cubic top-0  left-0 right-0 fixed flex border border-r-0 border-l-0 border-t-0 items-center justify-between flex-wrap bg-white px-10 py-4 -translate-y-32'
      }
    >
      <div className="flex items-center flex-shrink-0 mr-6">
        <Link
          onClick={props.goBack}
          style={{ margin: '0 -1rem' }}
          className="inline-flex px-2 transition-all duration-200 ease-out-cubic text-gray-400  align-center hover:text-black "
          to={`/s?${query && query !== 'undefined' ? query : ''}`}
        >
          <ChevronLeft className="" />
          Back
        </Link>
      </div>
      <p className="text-lg">{props.business.name}</p>
      <a
        href={`tel:${props.business.phone}`}
        className="bg-primary-500 hover:bg-primary-700 text-white py-2 px-4 rounded text-md font-semibold"
      >
        Contact
      </a>
    </div>
  );
};

export default Nav;
