import React from 'react';
import { ReactComponent as Scribbles } from 'assets/scribbles/8.svg';
import { ReactComponent as ScribblesCircle } from 'assets/scribbles/82.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as Map } from 'assets/icons/map-pin.svg';
import { Footer } from 'components';
import { mapboxGeocode } from 'data';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const Home = (props) => {
  const [active, setActive] = React.useState(false);
  const [val, setVal] = React.useState();

  const [geocode, setGeocode] = React.useState([]);
  const onChange = React.useRef(
    _.debounce(async (value) => {
      setVal(value);
      const { features } = await mapboxGeocode(value, {
        lat: 38.2527,
        lng: -85.7585,
      });
      if (!features) return;
      setGeocode(
        _.map(features, (item) => ({
          value: item.place_name,
          label: item.place_name,
          center: item.center,
          id: item.id,
          bbox: item.bbox,
        })),
      );
    }, 150),
  ).current;
  // todo fix
  const useLocation = () => {
    if ('geolocation' in navigator) {
      const query = new URLSearchParams(props.location.search);

      navigator.geolocation.getCurrentPosition((position) => {
        query.delete('minLng');
        query.delete('minLat');
        query.delete('maxLng');
        query.delete('maxLat');
        query.set('center', [
          position.coords.longitude,
          position.coords.latitude,
        ]);

        props.history.push({ location: '/s', search: query.toString() });
      });
    }
  };

  const createLink = (data) => {
    const query = new URLSearchParams(props.location.search);
    if (data.bbox || data.center) {
      let minLng;
      let minLat;
      let maxLng;
      let maxLat;
      if (data.bbox) {
        minLng = data.bbox[0];
        minLat = data.bbox[1];
        maxLng = data.bbox[2];
        maxLat = data.bbox[3];
        query.set('minLng', minLng);
        query.set('minLat', minLat);
        query.set('maxLng', maxLng);
        query.set('maxLat', maxLat);
        query.delete('center');
      } else {
        query.delete('minLng');
        query.delete('minLat');
        query.delete('maxLng');
        query.delete('maxLat');
        query.set('center', data.center);
      }
      return `/s?${query.toString()}`;
    }
  };

  const Item = (props) => (
    <Link
      to={() => createLink(props)}
      className="block text-left rounded-lg hover:bg-gray-100 text-gray-600 hover:text-gray-900 px-4 py-2 w-full"
    >
      {props.label}
    </Link>
  );

  return (
    <>
      <div className="lg:px-0 px-6 md:w-auto  bg-neutral ">
        <div className="relative z-10 mx-auto max-w-6xl flex h-full py-24 md:py-48">
          <div className="md:pb-0 pb-12  flex  z-10 flex-col  items-start justify-center">
            <h1
              style={{ lineHeight: '3.75rem' }}
              className="text-5xl font-bold"
            >
              Quickly browse local <br />
              stores near you
            </h1>
            <p className="text-lg text-black opacity-75 mt-5">
              Support local businesses near you during a time when they need it
              most
            </p>
            <div className="h-12 mt-12 md:w-auto w-full pl-4 flex items-center rounded-lg flex bg-white relative shadow-xl">
              <button onClick={useLocation} className="">
                <Map className=" fill-current text-gray-400" />
              </button>
              <input
                onBlur={() => setTimeout(() => setActive(false), 200)}
                onFocus={() => setActive(true)}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Enter your location..."
                className="h-full outline-none flex-1 md:flex-none md:w-64 ml-4 mr-12"
              />
              <Link
                to="/s"
                className=" rounded-lg h-12 w-12 flex items-center justify-center bg-black"
              >
                <ArrowRight className="stroke-current text-white" />
              </Link>
              {active && val !== '' && (
                <div
                  style={{ top: '100%' }}
                  className=" mt-4 md:w-full w-auto left-0 top-0  w-full z-50 px-2 py-2 absolute shadow-2xl sm:right-auto  rounded-lg bg-white text-gray-500"
                >
                  {geocode.length === 0 && val !== '' ? (
                    <div className="w-full px-3 py-2">No results found</div>
                  ) : (
                    geocode.map((geo) => <Item {...geo} />)
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex hidden lg:flex shadow-2xl flex-shrink-0 absolute right-0 flex-1 justify-center">
            <img
              style={{ width: '24rem' }}
              className="-mt-12 relative z-10"
              src={process.env.PUBLIC_URL + '/julep.jpeg'}
            />
          </div>
        </div>
      </div>
      <div className="lg:px-0 px-6 md:w-auto text-gray-900 bg-white ">
        <div className="relative z-10 mx-auto max-w-6xl grid lg:grid-cols-2 grid-cols-1  md:py-56 py-32">
          <div className="flex z-10 flex-col  items-start justify-center">
            <h1
              style={{ lineHeight: '3.75rem' }}
              className="text-5xl font-bold"
            >
              Takeout shouldn’t <div className="text-primary-500">take out</div>
              the neighborhood.
            </h1>
            <p className="mt-8">
              Surging fees from third-party food delivery apps are proving
              unsustainable for independent restaurants.
              <br />
              <br />
              With commissions as high as 40% on every order — on top of a
              delivery fee and service charge — the average restaurant only nets
              a 6% profit. If a restaurant only keeps 60 cents of a $10
              sandwich, an added commission of 40% whittles their margins down
              to nothing. <br /> <br /> In many cases, restaurants lose money on
              these orders.
              <div className="text-primary-500 font-medium">
                This is simply not sustainable.
              </div>
            </p>
          </div>
          <div className="flex items-center lg:pt-0 pt-24 lg:items-end justify-center lg:justify-end flex-col">
            <div className="max-w-md">
              <div className="text-center w-full text-gray-600 pb-6">
                The true cost of a meal on a third-party platform
              </div>
              <div className="w-full rounded-md shadow-2xl px-8 py-10 ">
                <div className="text-lg font-medium mb-6">Guest check</div>
                <div className="grid border gap-3 pb-6 border-t-0 border-l-0 border-r-0 grid-cols-2">
                  <div>Cheeseburger</div>
                  <div className="text-right">$14.50</div>
                  <div>Chili fries</div>
                  <div className="text-right">$5.50</div>
                </div>
                <div className="grid border gap-3 py-6 border-t-0 border-l-0 border-r-0 grid-cols-2">
                  <div>Resturant gross income</div>
                  <div className="text-right text-green-500">$20.00</div>
                  <div>Resturant costs</div>
                  <div className="text-right text-red-500 ">-$18.00</div>
                </div>
                <div className="grid border gap-3 py-6 border-t-0 border-l-0 border-r-0 grid-cols-2">
                  <div>Resturant profit</div>
                  <div className="text-right ">$2.00</div>
                  <div>Third-party commission</div>
                  <div className="text-right text-red-500 ">-$8.00</div>
                </div>
                <div className="grid  gap-3 pt-6 grid-cols-2">
                  <div className="font-medium">Resturant loss</div>
                  <div className="text-right font-bold text-lg text-red-500">
                    -$6.00
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:px-0 px-6 md:w-auto text-black bg-lightPink ">
        <div className="relative gap-24 z-10 mx-auto max-w-6xl grid lg:grid-cols-2 grid-cols-1 py-32 md:py-48">
          <div>
            <img src={process.env.PUBLIC_URL + '/eating.png'} />
          </div>

          <div className="flex z-10 flex-col  items-start justify-center">
            <h1
              style={{ lineHeight: '3.75rem' }}
              className="text-5xl font-bold"
            >
              Without local restaurants, there goes the neighborhood.
            </h1>
            <p className="mt-8">
              Local restaurants are the lifeblood of our neighborhoods. Imagine
              a world without the wood-fired pizza place, the colorful corner
              seafood restaurant, or the beloved breakfast joint that’s been
              family owned and operated for over 50 years. Talented chefs
              without deep pockets will work for chains, cooking the same menu
              items served nationally. <br /> <br />
              Less creativity. Less risks. Less moms. Less pops. That’s why
              we’re working for a brighter future for the restaurant industry —
              one where local restaurants remain at the center of local culture.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
