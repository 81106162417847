import React, { useEffect } from 'react';
import useFetch, { createUseFetch } from 'fetch-suspense';
import Cookies from 'js-cookie';

export const BASE_URL = 'https://zackhigdon.com';
export const MAPBOX_TOKEN =
  'pk.eyJ1IjoibGlvbmdhdGUyIiwiYSI6ImNrODF3cTNnbTBmeDIzZXFxdTQwNnEzN3MifQ.F8UutDpmKuRofW6QVZD3Jw';
const BASE_MAPBOX_URL = 'https://api.mapbox.com';

export const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (Cookies.get('access_token')) {
    headers.Authorization = `Bearer ${Cookies.get('access_token')}`;
  }
  return headers;
};

export function mapboxReverseGeocode({ lat, long }) {
  return new Promise((res, rej) => {
    let status;
    fetch(
      `${BASE_MAPBOX_URL}/geocoding/v5/mapbox.places/${long},${lat}.json?access_token=${MAPBOX_TOKEN}`,
    )
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((data) => {
        if (status !== 200) {
          rej(data);
        } else {
          res(data);
        }
      });
  });
}

export function mapboxGeocode(text, { lat, lng }) {
  return new Promise((res, rej) => {
    let status;
    const prox = lng && lat ? `&proximity=${lng},${lat}` : '';
    fetch(
      `${BASE_MAPBOX_URL}/geocoding/v5/mapbox.places/${text}.json?autocomplete=true&country=US&language=en${prox}&types=postcode,address,place,region,neighborhood,locality,address&access_token=${MAPBOX_TOKEN}`,
    )
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((data) => {
        if (status !== 200) {
          rej(data);
        } else {
          res(data);
        }
      });
  });
}

export function fetchData(endpoint, opts) {
  return new Promise((res, rej) => {
    let status;
    console.log(endpoint, opts);
    fetch(BASE_URL + endpoint, {
      headers: getHeaders(),
      ...opts,
    })
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((data) => {
        if (status < 200 || status > 299) {
          rej(data);
        } else {
          res(data);
        }
      });
  });
}

function wrapPromise(promise) {
  let status = 'pending';
  let response;

  const suspender = promise.then(
    (res) => {
      status = 'success';
      response = res;
    },
    (err) => {
      status = 'error';
      response = err;
    },
  );
  const read = () => {
    switch (status) {
      case 'pending':
        throw suspender;
      case 'error':
        throw response;
      default:
        return response;
    }
  };

  return { read };
}

export function fetchStores() {
  return wrapPromise(fetchData('/stores'));
}

export function fetchStore(id) {
  return wrapPromise(fetchData, '/stores/' + id);
}

export const useData = (path, ...args) => useFetch(BASE_URL + path, ...args);

const storeCache = new Map();

export const useBusiness = (id) => useFetch(BASE_URL + '/stores/' + id);

const yelpCache = new Map();

export const useYelp = (id) => useFetch(BASE_URL + '/yelp/' + id);

export const useProfile = (id) => {
  return useFetch(BASE_URL + '/me', { method: 'POST', headers: getHeaders() });
};

export const useBusinesses = (id) => useFetch(BASE_URL + '/stores');

export const useCategories = () => useFetch(BASE_URL + '/categories');

export const useNearbyStores = (data) =>
  useFetch(BASE_URL + '/stores/nearby', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: getHeaders(),
  });
export const useMyCollections = () =>
  useFetch(BASE_URL + '/collection/me', {
    headers: getHeaders(),
  });

export const createCollection = (name) =>
  fetchData('/collection', { method: 'POST', body: JSON.stringify({ name }) });

export const updateCollection = (collection_id, name) =>
  fetchData('/collection', {
    method: 'PUT',
    body: JSON.stringify({ name, collection_id }),
  });

export const addStoreToCollection = (collection_id, store_id) =>
  fetchData('/collection/add', {
    method: 'POST',
    body: JSON.stringify({ store_id, collection_id }),
  });

export const useCollection = (id) => useFetch(BASE_URL + '/collection/' + id);
