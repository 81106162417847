import React from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { ReactComponent as NoContact } from 'assets/no-contact.svg';
import { ReactComponent as Delivery } from 'assets/delivery.svg';
import { ReactComponent as Takeout } from 'assets/takeout.svg';
import { ReactComponent as PhoneIllustration } from 'assets/illustrations/phone.svg';

import { withDevice } from 'hoc';
import { Head, Rating, Footer } from 'components';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import {
  useYelp,
  useBusiness,
  useData,
  useNearbyStores,
  MAPBOX_TOKEN,
} from 'data';
import _ from 'lodash';
import moment from 'moment';
import Header from './header';
import Nav from './nav';
import Photos from './photos';
import { Marker, Popup } from 'react-mapbox-gl';
import Review from 'components/review';
import './styles.css';

const Section = (props) => {
  if (props.hidden) return null;
  return (
    <div className="flex flex-col  w-full">
      {props.title && (
        <h1 className="text-xl font-semibold mb-6">{props.title}</h1>
      )}
      {props.children}
    </div>
  );
};

const InstructionSection = (props) =>
  props.visible ? (
    <div className="flex my-4">
      {props.icon}
      <div className="mt-1 text-gray-900 flex flex-col">
        <p className="font-semibold ">{props.title}</p>
        <p className="text-sm">{props.subtitle}</p>
      </div>
    </div>
  ) : null;

const TILE_SIZE = '6rem';

const DeliveryTile = (props) =>
  props.link ? (
    <a href={props.link} className="delivery-tile relative mr-4">
      <button
        style={{ ...props.style, height: TILE_SIZE, width: TILE_SIZE }}
        className="relative z-10 overflow-hidden rounded-lg text-white bg-primary-500"
      >
        <img
          style={props.imgStyle}
          className="w-full h-full object-contain"
          src={`${process.env.PUBLIC_URL}/logos/${props.name}.png`}
        />
      </button>
      <img
        style={{
          ...props.style,
          filter: 'blur(14px)',
          height: TILE_SIZE,
          width: TILE_SIZE,
        }}
        className="w-44 h-44 absolute top-0 z-0"
        src={`${process.env.PUBLIC_URL}/logos/${props.name}.png`}
      />
    </a>
  ) : null;

const NearbyStore = (props) => {
  return (
    <Link
      to={`/business/${props.id}`}
      className="select-none hover:bg-gray-100 -m-4 p-4 relative rounded-lg flex flex-1 flex-col text-gray-900"
    >
      <div className="w-full h-40 overflow-hidden">
        {props.image_url && (
          <img
            src={props.image_url}
            className="rounded-md object-cover w-full h-full"
          />
        )}
        {!props.image_url && (
          <div className="object-cover h-full w-full flex items-center justify-center text-gray-600 font-medium bg-gray-200 ">
            No images
          </div>
        )}
      </div>
      <h5 className="text-md font-semibold mt-4 mb-1">{props.name}</h5>
      <Rating reviewCount={props.review_count} rating={props.rating} />
      <div className="text-xs mt-1">
        {props.price}
        {props.categories_string &&
          typeof props.categories_string === 'string' &&
          props.categories_string.split('|').map((cat) => (
            <div className="inline-flex items-center" key={cat}>
              <p className="text-gray-700 mx-1">·</p>
              <p className="text-gray-700">{cat}</p>
            </div>
          ))}
      </div>
    </Link>
  );
};

const NERABY_OFFSET = 4;

export default withDevice((props) => {
  const businessId = _.get(props.match, 'params.id');
  let business = {};
  let yelp = {};
  let nearbyStores = {};
  if (businessId && !props.loading) {
    business = useBusiness(businessId);
    yelp = useYelp(businessId);
  }
  if (business && !props.loading) {
    nearbyStores = useNearbyStores({
      latitude: business.latitude,
      longitude: business.longitude,
    });
  }
  const loading = props.loading;
  const query = new URLSearchParams(
    props.location ? props.location.search : {},
  );
  React.useEffect(() => {
    if (!window.location.hostname.match('localhost')) {
      window.scrollTo(0, 0);
    }
  }, []);
  console.log('yelp', yelp);
  console.log('business', business);
  console.log('near', nearbyStores);
  const arrowClass =
    'z-10 bg-white -mr-6 mt-24 md:flex hidden rounded-full shadow-lg h-12 w-12 hover:shadow-xl transition-all duration-150 ease-out-cubic hover:scale-105 transform flex items-center justify-center absolute right-0 justify-center';
  const hours = _.find(yelp.hours, { hours_type: 'REGULAR' });
  const hasDelivery =
    !loading &&
    (business.uber_link ||
      business.postmates_link ||
      business.doordash_link ||
      business.grubhub_link);
  const renderHours = () => {
    const seenHours = {};
    let last;

    return _.map(hours.open, (hour, index) => {
      if (seenHours[hour.day]) {
        seenHours[hour.day] += 1;
      } else {
        seenHours[hour.day] = 1;
      }
      return (
        <div
          key={hour + index}
          className={cx({
            'grid grid-cols-2 text-sm ': true,
            'mt-2': seenHours[hour.day] === 1,
          })}
        >
          <p className="flex-1 font-semibold">
            {seenHours[hour.day] === 1 && moment().day(hour.day).format('ddd')}
          </p>
          <p className="flex-3">
            {`${moment(hour.start, 'hhmm').format('h:mm a')} - ${moment(
              hour.end,
              'hhmm',
            ).format('h:mm a')}`}
          </p>
        </div>
      );
    });
  };

  const curHour = parseInt(moment().format('kkmm'));
  const isOpen =
    loading || !hours
      ? false
      : _.some(
          hours.open,
          (hour, index) =>
            curHour >= parseInt(hour.start) && curHour <= parseInt(hour.end),
        );

  const rightElm = (
    <div className="md:ml-12 ml-0 z-50 md:relative fixed bottom-0 left-0 right-0 md:w-auto bg-white ">
      <div
        style={{
          width: props.isMedium ? '100%' : '20rem',
          top: 'calc(73px + 2rem)',
        }}
        className="sticky flex w-64 md:border-r border-r-0 md:border-l border-l-0  border flex-col p-6"
      >
        {!loading ? (
          <>
            {hours && (
              <>
                <div
                  className={cx({
                    'text-green-900 bg-green-200': isOpen,
                    'text-gray-800 bg-gray-200': !isOpen,

                    'p-2 top-0 right-0 left-0 absolute font-bold text-center uppercase leading-none text-xs': true,
                  })}
                  role="alert"
                >
                  {isOpen ? 'Open now' : 'Closed'}
                </div>
                {!props.isMedium && (
                  <>
                    <div className="h-5 w-5" />
                    <div className="flex flex-col mb-6 text-gray-900">
                      {renderHours()}
                    </div>
                  </>
                )}
              </>
            )}
            {!props.isMedium && hours && <hr className="mb-4" />}
            {business.phone && (
              <a
                href={`tel:${business.phone}`}
                className={cx(
                  'flex  justify-center items-center text-center bg-primary-500 hover:bg-primary-700 text-white py-1 md:py-2 px-4 rounded md:text-lg  font-semibold',
                  {
                    'mt-4': props.isMedium,
                  },
                )}
              >
                <Phone style={{ fill: 'white' }} className="h-4 w-4 mr-3" />
                Contact
              </a>
            )}
            {yelp.url && business.phone && <hr className=" my-8 " />}
            {yelp.url && (
              <>
                <a
                  target="_blank"
                  href={`${yelp.url}`}
                  className="flex justify-center items-center text-center bg-white md:hover:bg-gray-200 text-gray-900 md:py-2 px-4 rounded md:text-lg font-semibold"
                >
                  View on
                  <img
                    style={{ marginTop: '-3px' }}
                    className="h-6 ml-1"
                    src={`${process.env.PUBLIC_URL}/yelp.png`}
                  />
                </a>
              </>
            )}
          </>
        ) : (
          <div className="w-full h-12 bg-gray-200" />
        )}
      </div>
    </div>
  );
  const title = business.name ? `${business.name} - Loucal` : 'Loucal';
  const description = 'View this local business on Loucal';
  const [nearbyIndex, setNearbyIndex] = React.useState(0);

  const browser = navigator.appVersion;
  const locationLink =
    browser.indexOf('Mac') !== -1
      ? `http://maps.apple.com/?ll=${business.latitude},${business.longitude}`
      : `https://maps.google.com/?q=${business.latitude}, ${business.latitude}`;
  return (
    <>
      <div className="mb-64 mx-4 lg:mx-0">
        <Head
          title={title}
          description={description}
          image={business.image_url}
        />
        {!props.isMedium && (
          <Nav
            goBack={props.history.goBack}
            query={query}
            business={business}
          />
        )}
        <Header
          query={query}
          yelp={yelp}
          loading={loading}
          business={business}
        />
        <Photos
          isMedium={props.isMedium}
          loading={loading}
          photos={yelp.photos || []}
        />
        <div className="container max-w-screen-lg mx-auto pt-8 flex">
          <div className="md:mr-12 flex-1 flex flex-col">
            <Section>
              <InstructionSection
                visible={business.has_no_contact}
                title="No touch delivery"
                subtitle="This business provides no touch delivery"
                icon={<NoContact className="h-8 fill-current mr-5 w-7" />}
              />

              <InstructionSection
                visible={business.delivery}
                title="Delivery"
                subtitle="This business provides delivery"
                icon={<Delivery className="h-8 fill-current mr-5 w-7" />}
              />
              <InstructionSection
                visible={business.inhouse_carryout}
                title="Carryout"
                subtitle="This business offers carryout"
                icon={<Takeout className="h-8 fill-current mr-5 w-7" />}
              />
              {!loading && business.phone && (
                <div className="animated fadeIn p-6 mt-6 mb-2 border rounded-md flex justify-between items-center">
                  <div>
                    <div className="font-medium">
                      Support local and order directly by phone
                    </div>
                    <div className="text-gray-600">
                      Third-party platforms can take up to 30-40% in fees
                    </div>
                    <div className="flex mt-6 items-center">
                      <a
                        href={`tel:${business.phone}`}
                        className="flex text-primary-600 px-3 py-1 bg-primary-100 rounded-md font-medium hover:bg-primary-200 items-center"
                      >
                        Order now
                      </a>
                      <div className="text-gray-600 ml-4 flex items-center">
                        {business.phone}
                      </div>
                    </div>
                  </div>
                  <div className=" flex-shrink-0 mx-4">
                    <PhoneIllustration className="h-18 w-18" />
                  </div>
                </div>
              )}
            </Section>
            {business.inhouse_carryout ||
            business.delivery ||
            business.has_no_contact ? (
              <hr className="mt-8 mb-12" />
            ) : null}

            <Section hidden={!hasDelivery} title="Order delivery">
              <div className="flex">
                <DeliveryTile
                  style={{ background: 'black' }}
                  name="postmates"
                  link={business.postmates_link}
                  imgStyle={{ transform: 'scale(1.1)' }}
                />
                <DeliveryTile
                  link={business.uber_link}
                  style={{ background: 'black' }}
                  imgStyle={{ transform: 'scale(0.8)' }}
                  name="uber-eats"
                />
                <DeliveryTile
                  link={business.doordash_link}
                  style={{ background: '#FE3108' }}
                  name="doordash"
                />
                <DeliveryTile
                  link={business.grubhub_link}
                  style={{ background: '#F73540' }}
                  imgStyle={{ transform: 'scale(0.8)' }}
                  name="grubhub"
                />
              </div>
            </Section>

            {hasDelivery && <hr className="my-12" />}
            <Section title="Location">
              <div className="rounded-l pt-1 flex flex-col">
                <div className="w-full lg:h-64 h-full overflow-hidden rounded-lg">
                  {!loading ? (
                    <div className="relative flex items-center justify-center">
                      <div className="absolute transition-all flex align-center justify-center bg-primary-500 duration-150 ease-out-cubic focus:outline-none transform -rotate-45 h-8 flex align-center justify-center rounded-br-full rounded-tr-full rounded-tl-full text-sm w-8">
                        <p
                          style={{ transform: 'translate(0rem, .5rem)' }}
                          className="font-bold transform rotate-45 h-4 w-4 rounded-full bg-white"
                        />
                      </div>
                      <img
                        className="rounded-lg"
                        src={`https://api.mapbox.com/styles/v1/mapbox/light-v10/static/${business.longitude},${business.latitude},15,0/600x260?access_token=${MAPBOX_TOKEN}`}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-0 flex-col justify-center items-start pt-6">
                  <p className="text-lg font-semibold">{business.street}</p>
                  <p className="pb-6">{business.city}</p>
                  <a
                    target="_blank"
                    href={locationLink}
                    className="bg-transparent hover:bg-primary-500 text-primary-500 font-semibold hover:text-white py-2 px-4 text-sm border border-primary-500 hover:border-transparent rounded"
                  >
                    View location
                  </a>
                </div>
              </div>
            </Section>
            <hr className="my-12" />

            <Section title="Reviews">
              {!yelp.reviews ||
                (yelp.reviews.length === 0 && (
                  <div className="rounded-lg bg-gray-100 text-gray-600 p-3">
                    This business has no reviews
                  </div>
                ))}
              {_.map(yelp.reviews, (review) => (
                <Review
                  key={review.id}
                  id={review.id}
                  createdAt={review.time_created}
                  name={review.user.name}
                  url={review.url}
                  profileUrl={review.user.profile_url}
                  imageUrl={review.user.image_url}
                  rating={review.rating}
                  description={review.text}
                />
              ))}
            </Section>
          </div>
          {props.isMedium
            ? ReactDOM.createPortal(rightElm, document.body)
            : rightElm}
        </div>

        <div className="container max-w-screen-lg mx-auto flex flex-col mb-64">
          <hr className="w-full my-12" />
          <Section title="People also viewed">
            <div className="relative grid md:grid-cols-4 grid-cols-1 sm:grid-cols-2 gap-6">
              <button
                onClick={() =>
                  setNearbyIndex(
                    nearbyIndex === 0 ? NERABY_OFFSET : nearbyIndex - 1,
                  )
                }
                className={'right-auto l-0 ' + arrowClass + ' mr-0 -ml-6'}
              >
                <ChevronLeft />
              </button>
              {nearbyStores.data
                ? nearbyStores.data
                    .slice(
                      nearbyIndex * NERABY_OFFSET,
                      (nearbyIndex + 1) * NERABY_OFFSET,
                    )
                    .map((store) => <NearbyStore query={query} {...store} />)
                : null}
              <button
                onClick={() =>
                  setNearbyIndex(
                    nearbyIndex === NERABY_OFFSET ? 1 : nearbyIndex + 1,
                  )
                }
                className={arrowClass}
              >
                <ChevronRight />
              </button>
            </div>
          </Section>
        </div>
      </div>
      <Footer />
    </>
  );
});
