import React from 'react';
import { Footer } from 'components';
import { createCollection, useMyCollections, addStoreToCollection } from 'data';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';

const Inner = (props) => {
  const collections = useMyCollections();
  return (
    <div>
      {collections.map((collection) => (
        <Link
          to={`/collection/${collection.id}`}
          className="hover:bg-gray-100 px-6 py-4 flex items-center rounded-lg"
        >
          <div className="bg-secondary w-16 h-16 flex items-center justify-center text-white font-bold rounded-lg text-2xl mr-4">
            {collection.stores.length}
          </div>
          <div>
            <div>{collection.name}</div>
            <div className="text-gray-600">
              Created {moment(collection.createdAt).fromNow()}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};
export default (props) => {
  const query = localStorage.getItem('query');

  return (
    <>
      <div className="flex text-gray-900 flex-col bg-neutral pb-24 pt-16 h-auto flex align-center ">
        <div style={{ maxWidth: '40rem' }} className="container mx-auto">
          <Link
            className="hover:underline flex -ml-2 mb-2"
            to={`/?${query && query !== 'undefined' ? query : ''}`}
          >
            <ChevronLeft /> Back
          </Link>
          <h1 className="font-semibold text-2xl ">Collections</h1>

          <div className="mt-8 mx-2 w-full bg-white rounded-md px-8 mx-auto flex flex-col align-center py-12">
            <React.Suspense fallback={null}>
              <Inner />
            </React.Suspense>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
