import React, {
  Suspense,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { Helmet } from 'react-helmet';

import Map from 'components/map';

import BusinessCard from 'components/business-card';
import { useData, useMyCollections } from 'data';
import Pagination from 'components/pagination';
import cx from 'classnames';
import _ from 'lodash';
import { Marker, Popup } from 'react-mapbox-gl';
import { Link } from 'react-router-dom';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';
import { ReactComponent as Close } from 'assets/icons/x.svg';
import { ReactComponent as CheckSquare } from 'assets/icons/check-square.svg';
import BusinessesMap from './map';
import './styles.css';
import { withDevice } from 'hoc';
import Filters from './filters';
import { getSearchParamHeaders } from 'utils';
import { SearchInput, Modal, RowSpacer } from 'components';
import { ReactComponent as Illustration } from 'assets/illustrations/food.svg';
import Businesses from './businesses';

const Search = (props) => {
  const [selected, setSelected] = useState();
  const [toggleMap, setToggleMap] = useState(false);
  const query = new URLSearchParams(props.location.search);
  const [location, setLocation] = useState();
  const [welcomeModal, setWelcomeModal] = useState(
    !localStorage.getItem('welcome'),
  );
  const closeModal = () => {
    localStorage.setItem('welcome', true);
    setWelcomeModal(false);
  };
  return (
    <div style={{ height: '100vh' }} className="flex-1 flex">
      <Helmet>
        <title>Search - Loucal</title>
      </Helmet>
      <Modal size="sm" isOpen={welcomeModal}>
        <div className="flex items-center flex-col mb-2">
          <Illustration />
          <h2 className="text-center font-semibold mt-3 text-2xl">Howdy! 🤠</h2>
          <p className="text-center mt-3">
            Loucal is a platform built to discover local small businesses in
            Kentucky. Have any suggestions or feedback?
            <br />
            <a
              target="_blank"
              className="text-primary-500 hover:underline"
              href="https://www.facebook.com/groups/144293743640349"
            >
              Join our Facebook group.
            </a>
          </p>
          <button
            onClick={closeModal}
            className="transform transition-all duration-150 ease-out-cubic hover:bg-primary-200 bg-primary-100 text-primary-600 py-2 w-full rounded-lg font-semibold mt-8"
          >
            Let's go!
          </button>
        </div>
      </Modal>

      <div
        style={{ width: props.isMedium ? '100%' : '40rem' }}
        className="relative z-10 flex md:px-8 px-4 md:py-10 pt-12 pb-24 flex-col "
      >
        <Suspense
          fallback={
            <div className="pt-20">
              <div className="h-12 block w-12" />
              {_.times(6, (i) => (
                <BusinessCard
                  isFirst={i === 0}
                  isMedium={props.isMedium}
                  key={i}
                  loading
                />
              ))}
            </div>
          }
        >
          <Businesses
            location={location}
            auth={props.auth}
            isMedium={props.isMedium}
            push={props.history ? props.history.push : null}
            query={query}
            selected={selected}
          />
        </Suspense>
        {props.isMedium && <RowSpacer size="2xl" />}
      </div>
      {props.isMedium && (
        <button
          onClick={() => setToggleMap(true)}
          style={{ bottom: '5rem', left: '50%', transform: 'translateX(-50%)' }}
          className="font-semibold bg-gray-900 text-white rounded-full h-10 flex items-center px-8 fixed z-40"
        >
          <MapIcon className="w-4 mr-2 h-4" />
          Map
        </button>
      )}
      {(!props.isMedium || toggleMap) && (
        <div className="flex-grow md:z-0 z-50 bottom-0 left-0 top-0 right-0 fixed md:static">
          <div
            style={{
              height: props.isMedium
                ? `calc(100% - ${toggleMap ? '0px' : '73px'})`
                : '100%',
              top: '73px',
            }}
            className="h-full w-full"
          >
            <BusinessesMap
              onReverseGeocode={(data) => {
                const city = _.find(data, (d) => d.place_type[0] === 'place');
                if (city) {
                  setLocation(city.text);
                }
              }}
              location={props.location}
              query={query}
              push={props.history ? props.history.push : null}
              selected={selected}
              setSelected={setSelected}
            >
              {props.isMedium && (
                <button
                  onClick={() => setToggleMap(false)}
                  style={{
                    boxShadow:
                      'rgba(0, 0, 0, 0.2) 0px 6px 20px, rgba(0, 0, 0, 0.04) 0px 0px 0px 1px',
                  }}
                  className="top-0 left-0 m-4 font-semibold bg-white text-gray-900 rounded-full h-10 flex items-center w-10 justify-center fixed z-40"
                >
                  <Close />
                </button>
              )}
            </BusinessesMap>
          </div>
        </div>
      )}
    </div>
  );
};

export default withDevice(Search);
