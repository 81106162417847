import React, { Suspense } from "react";
import moment from "moment";
import { ReactComponent as Yelp } from "assets/yelp-icon.svg";

export default props => {
  return (
    <div className="flex flex-col mb-8 mt-4">
      <div className="flex">
        <div className="relative">
          <img
            src={props.imageUrl}
            alt=""
            className="bg-gray-200 rounded-full h-12 w-12 mr-4"
          />
          <div
            style={{ background: "red" }}
            className="rounded-full flex items-center justify-center h-5 w-5 overflow-hidden absolute -mt-4  border-2 border border-white"
          >
            <Yelp className="h-4 w-4" />
          </div>
        </div>

        <div className="flex text-gray-900 flex-col align-center">
          <div className="font-semibold ">{props.name}</div>
          <div className="text-gray-600 text-sm ">
            {moment(props.createdAt).format("MMMM D, YYYY")}
          </div>
        </div>
      </div>
      <div className="flex text-gray-900 mt-4 flex flex-col">
        {props.description}
      </div>
      <a
        target="_blank"
        className="inline text-red-500 mt-4 hover:opacity-50"
        href={props.url}
      >
        Read more
      </a>
    </div>
  );
};
