import React from 'react';
import { Footer } from 'components';
import { createCollection, useBusiness, useCollection } from 'data';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { BusinessCard } from 'components';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';

const Business = (props) => {
  const business = useBusiness(props.store_id);
  console.log(business);
  return (
    <BusinessCard
      link={`/business/${props.store_id}`}
      hideCollection
      openNewTab
      pageId={props.pageId}
      {...business}
    />
  );
};

const Header = (props) => {
  const collection = useCollection(props.id);
  return (
    <h1 className="font-semibold flex text-2xl ">
      Collection
      <div className="opacity-75 font-medium ml-2">{collection.name}</div>
    </h1>
  );
};

const Inner = (props) => {
  const collection = useCollection(props.id);
  console.log(collection);
  return (
    <div>
      {collection.stores.map((c, id) => (
        <Business pageId={id + 1} {...c} />
      ))}
    </div>
  );
};
export default (props) => {
  console.log(props);
  return (
    <>
      <div className="flex text-gray-900 flex-col bg-neutral pb-24 pt-16 h-auto flex align-center ">
        <div style={{ maxWidth: '40rem' }} className="container mx-auto">
          <Link className="hover:underline flex -ml-2 mb-2" to="/collections">
            <ChevronLeft /> Collections
          </Link>
          <React.Suspense fallback={null}>
            <Header id={props.match.params.id} />
          </React.Suspense>
          <div className="mt-8 mx-2 w-full bg-white rounded-md px-8 mx-auto flex flex-col align-center p-4">
            <React.Suspense fallback={null}>
              <Inner id={props.match.params.id} />
            </React.Suspense>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
