import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useProfile, useCategories } from 'data';
import moment from 'moment';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { Footer } from 'components';
import { ReactComponent as Left } from 'assets/icons/arrow-left.svg';
import Cookies from 'js-cookie';
import UserContext from 'context/UserContext';

const Field = ({ name, val, to, label }) => (
  <div className="grid md:grid-cols-2 grid-rows-1 gap-1">
    <div className="font-medium">{name}</div>
    <div className="flex flex-col">
      <div className="break-words">{val}</div>
      {to ? (
        <Link
          className="flex font-medium mt-2 text-primary-500 hover:underline"
          to={to}
        >
          {label} <ChevronRight />
        </Link>
      ) : null}
    </div>
  </div>
);
const Details = (props) => {
  const { logOut, user } = useContext(UserContext);

  return (
    user && (
      <div className=" grid grid-rows-1 gap-6">
        <Field name="First name" val={user.first_name} />
        <Field name="Last name" val={user.last_name} />

        <Field name="Email" val={user.email} />
        <hr className="my-2" />
        <Field
          to={user.stores.length ? '/stores' : null}
          label="View stores"
          name="Stores"
          val={
            user.stores.length > 0
              ? `You own ${user.stores.length} store${
                  user.stores.length === 1 ? '' : 's'
                }`
              : 'You do not own any stores'
          }
        />

        <hr className="my-2" />
        <button
          onClick={logOut}
          className="self-start text-md text-primary-600 font-medium  px-4 py-2 mb-2 hover:bg-primary-200 bg-primary-100 rounded-md hover:bg-primary-100"
        >
          Log out
        </button>
        <span className="text-center text-gray-500">
          User since {moment(user.created_at).format('MMMM D, YYYY')}
        </span>
      </div>
    )
  );
};
export default (props) => {
  const query = localStorage.getItem('query');
  return (
    <>
      <div className="h-screen text-gray-900 flex flex-col bg-neutral h-full flex relative">
        <Helmet>
          <title>Your Profile - Loucal</title>
        </Helmet>
        <div className="flex-col md:mx-auto mx-4 relative z-10 container max-w-screen-md w-auto flex align-center justify-start">
          <Link
            className="md:mt-24 mt-12 md:inline-flex self-start hidden p-8  text-gray-900 px-2 -mx-2 opacity-50 hover:opacity-100"
            to={`/s?${query && query !== 'undefined' ? query : ''}`}
          >
            <Left />
          </Link>
          <h1 className="md:mt-0 mt-12 font-semibold md:text-3xl text-2xl">
            Profile
          </h1>
          <div className="mt-6 mb-64 w-full md:w-full bg-white rounded-md p-6 md:p-12 mx-auto flex flex-col align-center">
            <React.Suspense fallback={null}>
              <Details push={props.history.push} />
            </React.Suspense>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
