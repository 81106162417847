import React from 'react';
import { Helmet } from 'react-helmet';

export default (props) => {
  return (
    <Helmet
      meta={[
        // facebook
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:title', content: props.title },
        {
          property: 'og:description',
          content: props.description,
        },
        { property: 'og:url', content: window.location.href },
        { property: 'og:image', content: props.image },
        // twitter
        { name: 'twitter:image', content: props.image },
        { name: 'twitter:url', content: window.location.href },
        { name: 'twitter:title', content: props.title },
        { name: 'twitter:description', content: props.description },
      ]}
      title={props.title}
    />
  );
};
