import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Check } from 'assets/icons/check-fat.svg';
import _ from 'lodash';
import { RowSpacer } from 'components';
import { withDevice } from 'hoc';

const CheckPill = (props) => (
  <div
    className={classNames(
      'h-6 flex ml-2 items-center justify-center w-6 mr-2 rounded-lg border ',
      {
        'bg-primary-500 border-primary-500 text-white': props.checked,
      },
    )}
  >
    {props.checked && <Check className="h-4 w-4 stroke-current" />}
  </div>
);

class Dropdown extends React.PureComponent {
  state = { price: [] };
  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.setState({ price: this.props.price });
    } else if (prevProps.visible && !this.props.visible) {
      this.props.updateParams('price', this.state.price);
    }
  }

  update = (key) => {
    const { price } = this.state;
    if (price.indexOf(key) > -1) {
      this.setState({
        price: _.compact(_.map(price, (p) => (p === key ? null : p))),
      });
    } else {
      this.setState({ price: price.concat([key]) });
    }
  };

  onClick = (e) => {
    this.update(e.target.getAttribute('data-key'));
    e.stopPropagation();
  };

  render() {
    if (!this.props.visible) return null;
    const btnClass =
      'hover:bg-gray-100 rounded-lg   w-full flex items-center justify-start text-left h-8';
    return (
      <div
        style={{ top: this.props.isMedium ? null : '2.5rem' }}
        className="left-0 mx-4 md:mx-auto md:left-auto md:right-auto right-0  md:w-64 z-50 px-6 py-8 md:absolute fixed w-auto shadow-2xl rounded-lg bg-white text-gray-900"
      >
        <button data-key="1" onClick={this.onClick} className={btnClass}>
          <CheckPill checked={this.state.price.indexOf('1') > -1} />$
        </button>
        <RowSpacer size="sm" />
        <button data-key="2" onClick={this.onClick} className={btnClass}>
          <CheckPill checked={this.state.price.indexOf('2') > -1} />
          $$
        </button>
        <RowSpacer size="sm" />

        <button data-key="3" onClick={this.onClick} className={btnClass}>
          <CheckPill checked={this.state.price.indexOf('3') > -1} />
          $$$
        </button>
        <RowSpacer size="sm" />

        <button data-key="4" onClick={this.onClick} className={btnClass}>
          <CheckPill checked={this.state.price.indexOf('4') > -1} />
          $$$$
        </button>
      </div>
    );
  }
}

const FilterPill = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{ lineHeight: '2rem', height: '2.25rem' }}
      className={classNames(
        'select-none items-center cursor-pointer relative text-sm rounded-full m-1  inline-block px-4 border ',
        {
          'border-primary-300 bg-primary-100 text-primary-900 font-semibold':
            props.toggled,
          'text-gray-900 border-gray-400': !props.toggled,
        },
      )}
    >
      {props.children}
    </div>
  );
};
const Filters = (props) => {
  const url = new URLSearchParams(props.query);

  const updateParams = (key, val) => {
    url.set(key, val);
    props.push({ location: '/s', search: url.toString() });
  };
  const [showDropdown, setShowDropdown] = React.useState(false);
  const open = url.get('open') && url.get('open') === 'true';
  const carryout = url.get('carryout') && url.get('carryout') === 'true';

  const delivery = url.get('delivery') && url.get('delivery') === 'true';
  const price = url.get('price') ? url.get('price').split(',') : [];
  return (
    <div className=" -m-1 my-4">
      <FilterPill
        toggled={price.length > 0}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        Price
        <Dropdown
          isMedium={props.isMedium}
          visible={showDropdown}
          updateParams={updateParams}
          price={price}
        />
        {showDropdown && (
          <div
            onClick={() => setShowDropdown(false)}
            className="fixed z-40 top-0 left-0 right-0 bottom-0"
          />
        )}
      </FilterPill>
      {/* <FilterPill
        onClick={() => updateParams('open', open ? 'false' : 'true')}
        toggled={open}
      >
        Open now
      </FilterPill> */}
      <FilterPill
        onClick={() => updateParams('delivery', delivery ? 'false' : 'true')}
        toggled={delivery}
      >
        Delivery
      </FilterPill>
      <FilterPill
        onClick={() => updateParams('carryout', carryout ? 'false' : 'true')}
        toggled={carryout}
      >
        Carryout
      </FilterPill>
    </div>
  );
};

export default withDevice(Filters);
