import Modal from 'components/modal';
import React from 'react';
import { Input, Label } from './';
import { ReactComponent as Celebrate } from 'assets/illustrations/celebrate.svg';
import { createCollection, useMyCollections, addStoreToCollection } from 'data';
import classNames from 'classnames';
import _ from 'lodash';
const Collection = (props) => (
  <button
    onClick={() => props.onClick(props.id)}
    className={classNames(
      'bg-primary-100 h-20 rounded-lg text-primary-800 font-medium',
      {
        'bg-primary-600 text-white': props.selected,
        'hover:bg-primary-200': !props.selected,
      },
    )}
  >
    {props.name}
  </button>
);
export default (props) => {
  const [name, setName] = React.useState('');
  const [success, setSuccess] = React.useState(false);

  const [selectedCollections, setSelectedCollections] = React.useState();
  let collections = useMyCollections();

  collections = collections.filter((c) =>
    c ? !c.stores.find(({ store_id }) => store_id === props.id) : false,
  );

  const addToCollection = async (id) => {
    if ((id || selectedCollections) === undefined) return;
    try {
      console.log('add', id || selectedCollections, props.id);
      await addStoreToCollection(id || selectedCollections, props.id);
    } catch (e) {
      console.log(e);
    }
    setSuccess(true);
    setTimeout(() => props.toggleModal(), 2000);
  };

  const newCollection = async () => {
    if (!name) return;
    try {
      const collection = await createCollection(name);
      console.log('addd', collection);
      await addToCollection(collection.id);
    } catch (e) {
      console.log(e);
    }
  };

  const [hasNoCollections, setNoCollections] = React.useState(
    collections.length === 0,
  );

  const renderCollections = () => (
    <>
      <div className="border-t border-b py-8 text-gray-900 flex items-center">
        <div className="h-16 mr-6 w-16 overflow-hidden">
          <img
            src={props.imageUrl}
            className="object-cover rounded h-full w-full bg-gray-100 "
          />
        </div>
        <div>
          <div className="text-xl font-semibold">{props.name}</div>
          <div className="text-md">{props.street}</div>
        </div>
      </div>
      <div className="pb-1 mt-6 font-medium text-gray-900 ">
        Select a collection
      </div>
      <div className="grid grid-cols-2 gap-4 pt-6">
        {collections.map((c) => (
          <Collection
            key={c.id}
            onClick={(id) => setSelectedCollections(id)}
            selected={selectedCollections === c.id}
            {...c}
          />
        ))}
      </div>
      <button
        onClick={() => addToCollection()}
        disabled={!selectedCollections}
        className="transform transition-all duration-150 ease-out-cubic hover:bg-primary-600 bg-primary-500 text-white py-2 w-full rounded-lg font-semibold mt-8"
      >
        Add to collection
      </button>
    </>
  );
  const renderCreateCollection = () => (
    <div className="flex flex-col items-center ">
      <div className="font-lg w-full mb-2">
        Save and share colletions of your favorite stores
      </div>
      <Input
        onChange={(name, val) => setName(val)}
        value={name}
        placeholder="Enter a name..."
        className="mt-2"
      />
      <button
        onClick={newCollection}
        className="transform transition-all duration-150 ease-out-cubic hover:bg-primary-200 bg-primary-100 text-primary-600 py-2 w-full rounded-lg font-semibold mt-8"
      >
        Create collection
      </button>
    </div>
  );

  const renderSuccess = () => (
    <div className="flex flex-col items-center ">
      <Celebrate />
      <h2 className="text-2xl my-4">Collection updated!</h2>
    </div>
  );
  return (
    <Modal
      size="sm"
      isOpen={props.isOpen}
      onClose={success ? null : props.toggleModal}
      title={
        success
          ? null
          : hasNoCollections
          ? 'Create a collection'
          : 'Add to collection'
      }
    >
      {success
        ? renderSuccess()
        : hasNoCollections
        ? renderCreateCollection()
        : renderCollections()}
    </Modal>
  );
};
