import React, { useContext } from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';

const MEDIUM = 768;

export const withDevice = (Component) => {
  return class extends React.PureComponent {
    state = {
      isMedium: window.innerWidth < MEDIUM,
    };

    componentDidMount() {
      window.addEventListener('resize', this.windowChange);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.windowChange);
    }

    windowChange = () => {
      const isMedium = window.innerWidth < MEDIUM;
      this.setState({ isMedium });
    };

    render() {
      return <Component {...this.props} isMedium={this.state.isMedium} />;
    }
  };
};


export const withContext = (Context, Component) => {
  return (props) => {
    const context = useContext(Context)

    return <Component {...props} context={context} />
  }
}
export const withToast = (Component) => {
  return (props) => {
    const [shown, setShown] = React.useState(false);

    const toggleToast = (text) => {
      setShown(text);
      setTimeout(() => setShown(false), 3000);
    };
    let elm;
    if (shown) {
      elm = (
        <div className="animated fadeIn fixed mx-6 my-4 top-0 right-0 z-50 bg-black text-white px-6 py-3 rounded-lg  shadow-lg  ">
          {shown}
        </div>
      );
    }
    return (
      <>
        <Component toggleToast={toggleToast} {...props} />
        {ReactDOM.createPortal(elm, document.body)}
      </>
    );
  };
};
