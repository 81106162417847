import React from 'react';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import { BASE_URL, getHeaders } from 'data';

const UserContext = React.createContext();
export default UserContext;

const Provider = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      auth: Cookies.get('access_token'),
      logIn: this.logIn,
      fetchUser: this.fetchUser,
      logOut: this.logOut,
    };
  }

  componentDidMount() {
    if (this.state.auth) {
      this.fetchUser();
    }
  }

  fetchUser = async () => {
    const res = await fetch(BASE_URL + '/me', {
      method: 'POST',
      headers: getHeaders(),
    });
    const user = await res.json();
    this.setState({ user: user[0] });
  };

  logOut = () => {
    Cookies.remove('access_token');
    this.setState({ user: null }, () => {
      this.props.history.push('/s');
    });
  };

  logIn = (user) => {
    this.setState({ user });
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
};

export const UserContextProvider = withRouter(Provider);
