import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from 'react-router-dom';
import {
  useProfile,
  fetchData,
  mapboxGeocode,
  mapboxReverseGeocode,
} from 'data';
import { RowSpacer } from 'components';
import cx from 'classnames';
import _ from 'lodash';
import { withDevice, withToast } from 'hoc';
import { ReactComponent as Close } from 'assets/icons/x.svg';
import Categories from 'data/categories.json';
import './navigation.css';
import { ReactComponent as Location } from 'assets/location.svg';

class Dropdown extends React.PureComponent {
  state = { price: '', selected: 0 };
  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      document.addEventListener('keydown', this.handleKey);
      this.setState({ price: this.props.price });
    } else if (prevProps.visible && !this.props.visible) {
      document.removeEventListener('keydown', this.handleKey);
    }

    if (prevProps.value !== this.props.value) {
      this.setState({ selected: 0 });
    }
  }

  handleKey = ({ keyCode }) => {
    const { selected } = this.state;
    if (keyCode === 38) {
      this.setState({
        selected: selected === 0 ? this.optionsLength - 1 : selected - 1,
      });
    } else if (keyCode === 40) {
      this.setState({
        selected: selected === this.optionsLength - 1 ? 0 : selected + 1,
      });
    } else if (keyCode === 13) {
      if (!_.get(this.options, this.state.selected)) return;
      this.props.onSubmit(this.options[this.state.selected]);
    }
  };

  update = (key) => {
    const { price } = this.state;
    if (price.indexOf(key) > -1) {
      this.setState({
        price: _.compact(_.map(price, (p) => (p === key ? null : p))),
      });
    } else {
      this.setState({ price: price.concat([key]) });
    }
  };

  onClick = (e) => {
    this.update(e.target.getAttribute('data-key'));
    e.stopPropagation();
  };

  renderItem = ({ value, label, match, id }, index) => {
    const btnClass =
      'rounded-lg hover:bg-gray-100 w-full max-w-lg overflow-hidden flex items-center justify-start text-left h-10 px-4';
    const end = label.substring(match.index + match[0].length, label.length);
    let lastIsSpace = false;
    const renderLabel = (val) =>
      val
        ? val.split('').map((item) => {
            let v = item;
            if (lastIsSpace) v = item.toUpperCase();
            lastIsSpace = item === ' ';
            return item === ' ' ? <>&nbsp;</> : v;
          })
        : '';
    const start = label.substring(0, match.index);
    return (
      <button
        key={value || id}
        onClick={() => this.props.onSubmit({ value, label, id })}
        data-key={value}
        className={cx(btnClass, {
          'bg-gray-100 text-gray-600': this.state.selected === index,
        })}
      >
        {renderLabel(start)}
        <span className="text-gray-900 font-semibold">
          {renderLabel(start ? match[0] : _.upperFirst(match[0]))}
        </span>
        {renderLabel(_.truncate(end))}
      </button>
    );
  };

  optionsLength = 5;

  render() {
    if (!this.props.visible) return null;
    const total = _.filter(this.props.options, (opt) =>
      opt.label.toLowerCase().match(this.props.value.toLowerCase()),
    );
    const options = _.map(_.take(total, 5), (opt) => ({
      ...opt,
      match: opt.label.toLowerCase().match(this.props.value.toLowerCase()),
    }));
    this.optionsLength = options.length;
    this.options = options;
    return (
      <div
        style={{ top: '100%' }}
        className="sm:mt-0 mt-1 left-0 top-0  w-auto z-50 px-4 py-4 absolute shadow-2xl sm:right-auto  rounded-lg bg-white text-gray-500"
      >
        {options.length === 0 ? <div className="w-64">No results</div> : null}
        {options.map(this.renderItem)}
      </div>
    );
  }
}

const Wrapper = ({ active, children }) => (
  <div
    className={cx(
      'transition-all duration-300 ease-out-cubic h-12 flex-shrink-0 relative flex items-center rounded-lg flex ',
      {
        'bg-white shadow-md': active,
        'bg-gray-100 shadow-none': !active,
      },
    )}
  >
    {children}
  </div>
);

class SearchInput extends React.PureComponent {
  state = {
    geocode: [],
    foodTags: [],
    geocodeValue: '',
    geocodeActive: false,
    categoryValue: '',
    categoryActive: false,
  };

  componentDidMount() {
    const query = this.getQuery();
    this.categories = new Map();
    Categories.map((item) => {
      this.categories.set(`${item.id}`, item.name);
    });
    this.updateFoodTagsFromQuery();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateFoodTagsFromQuery();
      this.updateGeocodeValue();
    }
  }

  updateGeocodeValue = () => {
    const query = this.getQuery();

    const hasBounds =
      query.has('maxLng') &&
      query.has('maxLat') &&
      query.has('minLng') &&
      query.has('minLat');

    if (!query.has('center') && !hasBounds) {
      this.setState({ geocodeValue: '' });
    }
  };

  updateFoodTagsFromQuery = () => {
    const query = this.getQuery();

    const foodTags =
      query.has('tags') && query.get('tags') !== ''
        ? _.compact(
            query
              .get('tags')
              .split(',')
              .filter((id) => this.categories.has(id)),
          )
        : [];
    this.setState({ foodTags });
  };

  updateMapbox = async (e) => {
    const geocodeValue = e.currentTarget.value;
    this.setState({ geocodeValue });
    let centerLat;
    let centerLng;
    const query = this.getQuery();

    if (query.has('minLng') && query.has('maxLng')) {
      centerLng =
        (parseFloat(query.get('minLng')) + parseFloat(query.get('maxLng'))) / 2;
    }

    if (query.has('minLat') && query.has('maxLat')) {
      centerLat =
        (parseFloat(query.get('minLat')) + parseFloat(query.get('maxLat'))) / 2;
    }
    try {
      const { features } = await mapboxGeocode(geocodeValue, {
        lat: centerLat,
        lng: centerLng,
      });
      if (!features) return;
      this.setState({
        geocode: features.map((item) => ({
          value: item.place_name,
          label: item.place_name,
          center: item.center,
          id: item.id,
          bbox: item.bbox,
        })),
      });
    } catch (e) {
      console.log(e);
    }
  };

  removeFoodTag = (value) => {
    if (this.state.foodTags.indexOf(value) === -1) return;
    const query = this.getQuery();
    query.set(
      'tags',
      _.filter(this.state.foodTags, (c) => c !== value).join(','),
    );
    this.props.history.push({ location: '/s', search: query.toString() });
  };

  useLocation = () => {
    if ('geolocation' in navigator) {
      const query = this.getQuery();

      navigator.geolocation.getCurrentPosition(
        (position) => {
          query.delete('minLng');
          query.delete('minLat');
          query.delete('maxLng');
          query.delete('maxLat');
          query.set('center', [
            position.coords.longitude,
            position.coords.latitude,
          ]);

          this.props.history.push({ location: '/s', search: query.toString() });
          this.getAddress({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (e) => {
          this.props.toggleToast('Error getting location');
        },
      );
    }
  };

  getAddress = async ({ lat, long }) => {
    try {
      const a = await mapboxReverseGeocode({ lat, long });
      const address = a.features[0].place_name;
      this.setState({ geocodeValue: address });
    } catch (e) {
      console.error(e);
    }
  };

  getQuery = (search = this.props.location.search) =>
    new URLSearchParams(search);

  updateUrlFood = (data) => {
    if (this.state.foodTags.indexOf(data.value) > -1) return;
    const { foodTags } = this.state;
    foodTags.push(data.value);
    const query = this.getQuery();

    query.set('tags', foodTags.join(','));
    this.foodRef.value = '';
    this.props.history.push({ location: '/s', search: query.toString() });
    this.setState({ categoryActive: false });
  };

  updateUrlGeocode = ({ id }) => {
    const data = _.find(this.state.geocode, { id }) || {};
    console.log(data);
    if (data.bbox || data.center) {
      const query = this.getQuery();
      let minLng;
      let minLat;
      let maxLng;
      let maxLat;
      if (data.bbox) {
        minLng = data.bbox[0];
        minLat = data.bbox[1];
        maxLng = data.bbox[2];
        maxLat = data.bbox[3];
        query.set('minLng', minLng);
        query.set('minLat', minLat);
        query.set('maxLng', maxLng);
        query.set('maxLat', maxLat);
        query.delete('center');
      } else {
        query.delete('minLng');
        query.delete('minLat');
        query.delete('maxLng');
        query.delete('maxLat');
        query.set('center', data.center);
      }

      this.locationRef.value = data.label;
      this.props.history.push({ location: '/s', search: query.toString() });
      this.setState({ geocodeActive: false, geocodeValue: data.label });
    }
  };

  renderGeocode = () => (
    <>
      <label
        className={cx(
          'h-full flex items-center pl-4 text-gray-600 text-sm mr-2',
          {
            'border border-t-0 border-b-0 border-r-0': this.state.geocodeActive,
          },
        )}
      >
        Near
      </label>
      <div className="md:relative static md:flex-none flex-1">
        <Dropdown
          onSubmit={this.updateUrlGeocode}
          options={this.state.geocode}
          visible={this.state.geocodeActive}
          value={this.state.geocodeValue}
        />
        <input
          ref={(c) => {
            this.locationRef = c;
          }}
          placeholder="Louisville..."
          onBlur={() =>
            setTimeout(() => this.setState({ geocodeActive: false }), 200)
          }
          value={this.state.geocodeValue}
          onFocus={() => this.setState({ geocodeActive: true })}
          onChange={this.updateMapbox}
          className={
            'text-sm pl-2 pr-6 border-t-0 border-b-0 mr-2 h-full mr-4  outline-none w-full sm:w-56 bg-transparent'
          }
        />
        <button
          onClick={this.useLocation}
          className="absolute right-0 px-2 focus:outline-none opacity-75 hover:opacity-100 top-0 mr-2 bottom-0 flex items-center"
        >
          <Location className="h-4 w-4" />
        </button>
      </div>
    </>
  );

  renderCategories = () => (
    <div className="md:relative static md:flex-none flex-1">
      <Dropdown
        onSubmit={this.updateUrlFood}
        options={Categories.filter(
          (c) => this.state.foodTags.indexOf(c.id + '') === -1,
        ).map((c) => ({ label: c.name, value: c.id }))}
        visible={this.state.categoryActive}
        value={this.state.categoryValue}
      />
      <input
        ref={(c) => {
          this.foodRef = c;
        }}
        onChange={(e) => this.setState({ categoryValue: e.target.value })}
        placeholder={
          this.state.foodTags.length > 0 ? '' : 'Tacos, pizza, etc...'
        }
        onBlur={() =>
          setTimeout(() => this.setState({ categoryActive: false }), 200)
        }
        onFocus={() => this.setState({ categoryActive: true })}
        className="text-sm h-full outline-none w-full sm:w-32 bg-transparent pr-4"
      />
    </div>
  );
  render() {
    const shiftRows =
      (this.state.foodTags.length || this.state.geocodeValue) &&
      this.props.isMedium;
    const query = this.getQuery();
    return (
      <>
        <Wrapper
          active={
            shiftRows
              ? this.state.categoryActive
              : this.state.categoryActive || this.state.geocodeActive
          }
        >
          <label className="ml-4 text-gray-600 text-sm mr-2">Find</label>
          {this.state.foodTags.map((tag) => (
            <div className="flex items-center bg-gray-300 rounded-md text-xs px-2 py-1 mr-2">
              {this.categories.get(tag)}
              <button
                onClick={() => this.removeFoodTag(tag)}
                className="ml-1 h-4 w-4 rounded-full hover:bg-gray-600 bg-gray-500 flex items-center justify-center"
              >
                <Close className="h-3 w-3 stroke-current text-white" />
              </button>
            </div>
          ))}
          {this.renderCategories()}
          {shiftRows ? null : this.renderGeocode()}
        </Wrapper>
        {shiftRows ? (
          <>
            <RowSpacer size="md" />
            <Wrapper active={this.state.geocodeActive}>
              {this.renderGeocode()}
            </Wrapper>
          </>
        ) : null}
      </>
    );
  }
}

export default withRouter(withDevice(withToast(SearchInput)));
