import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const sizes = ['xs', 'sm', 'base', 'lg', 'xl', '2xl']
const weights = ['light', 'normal', 'medium', 'semibold', 'bold', 'extrabold']
const color = ['red', 'black', 'gray']


const Text = props => {
  console.log(typeof props.children)
  return (
    <div className={classnames({
      [`text-${props.size}`]: props.size,
      [`font-${props.weight}`]: props.weight
    })}>
      {props.children}
    </div>
  )
}

Text.propTypes = {
  children: PropTypes.string.isRequired,
  size: PropTypes.oneOf(sizes),
  weight: PropTypes.oneOf(weights)
}

Text.defaultProps = {
  size: 'base',
  weight: 'normal'
}

export default Text;


