import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ReactComponent as Logo } from 'assets/logo-white.svg';
import _ from 'lodash';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';

import { ReactComponent as Check } from 'assets/icons/check.svg';
import { withDevice } from 'hoc';
import Modal from 'components/modal';
import { useYelp, useBusiness, useData, fetchData } from 'data';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { Footer } from 'components';
const SetupModal = (props) => {
  let business = {};
  let yelp = {};
  business = useBusiness(props.businessId);
  yelp = useYelp(props.businessId);
  console.log(business);

  const changeCode = (e) => {
    if (e.target.value.length < 7 && e.target.value) setCode(e.target.value);
  };

  const [loading, setLoading] = React.useState(false);
  const [code, setCode] = React.useState(false);
  const [done, setDone] = React.useState(false);

  const verifyCode = () => {
    setLoading(true);
    setDone(false);
    fetchData('/verify/' + props.businessId, {
      method: 'POST',
      body: JSON.stringify({ verification_code: code }),
    })
      .then((login) => {
        setCode(false);
        setDone(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const phoneCall = () => {
    setLoading(true);
    setCode(false);
    fetchData('/claim/' + props.businessId, {
      method: 'POST',
    })
      .then((login) => {
        setCode(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const introElm = (
    <>
      <Phone className="h-8 w-8 mb-4" />
      <div className="mb-1 text-2xl font-semibold">Verify your identity</div>
      <div>
        You will get a call at <strong>{business.phone}</strong> with a 6 digit
        code
      </div>
      <a
        className="text-gray-500 hover:text-gray-900 hover:underline"
        href="mailto:admin@loucal.us"
      >
        Need help?
      </a>
      <button
        onClick={phoneCall}
        className="flex mt-8 justify-center items-center text-center bg-secondary  text-white py-2 px-6  rounded text-lg font-semibold"
      >
        Request phone call
      </button>
    </>
  );

  const codeElm = (
    <>
      <Phone className="shake h-8 w-8 mb-4" />
      <div className="mb-1 text-2xl font-semibold">Calling your phone now</div>
      <div>Enter your 6 digit verification code below</div>
      <a
        className="text-gray-500 hover:text-gray-900 hover:underline"
        href="mailto:admin@loucal.us"
      >
        Need help?
      </a>
      <input
        placeholder="123456"
        type="number"
        value={code}
        required={true}
        maxLength="6"
        onChange={changeCode}
        data-field="number"
        className="verify-input bg-white text-center mx-auto text-xl p-2 rounded-lg w-32 mt-6"
      />
      <button
        onClick={verifyCode}
        className="flex mt-8 justify-center items-center text-center bg-secondary  text-white py-2 px-6  rounded text-lg font-semibold"
      >
        Verify code
      </button>
    </>
  );
  const doneElm = (
    <>
      <Check className=" h-8 w-8 mb-4" />
      <div className="mb-1 text-2xl font-semibold">
        Your business is claimed!
      </div>
      <div>Congrats! You now own this business on Loucal.</div>

      <Link
        to={`/edit/${props.businessId}`}
        className="flex mt-8 justify-center items-center text-center bg-secondary  text-white py-2 px-6  rounded text-lg font-semibold"
      >
        Continue
      </Link>
    </>
  );
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.toggleModal}
      title="Claim business"
    >
      <div className="border-t border-b py-8 text-gray-900 flex items-center">
        <div className="h-16 mr-6 w-16 overflow-hidden">
          <img
            src={business.image_url}
            className="object-cover rounded h-full w-full bg-gray-100 "
          />
        </div>
        <div>
          <div className="text-xl font-semibold">{business.name}</div>
          <div className="text-md">{business.street}</div>
        </div>
      </div>
      <div className="py-12 text-center text-gray-900 flex flex-col flex items-center">
        {!code && !done && introElm}
        {code && !done && codeElm}
        {!code && done && doneElm}
      </div>
    </Modal>
  );
};
const CheckSection = (props) => {
  return (
    <div className="flex flex-col">
      <div className="flex border-gray-900 items-center mb-4 justify-center h-12 w-12 border rounded-full">
        <Check />
      </div>
      <h5 className="text-2xl my-2 font-semibold">{props.title}</h5>
      <p className="text-lg">{props.description}</p>
    </div>
  );
};

const Tag = (props) => {
  const store = useBusiness(props.id);
  return <>{store.city}</>;
};
export default withDevice((props) => {
  const [modal, toggleModal] = React.useState(false);
  const baseContainerStyle = { height: '66vh', minHeight: '800px' };
  const btnClass =
    'flex flex-shrink-0 mt-8 justify-center items-center text-center bg-secondary  text-white py-2 px-6  rounded text-lg font-semibold';
  let ctaElm = props.auth ? (
    <button onClick={() => toggleModal(true)} className={btnClass}>
      Claim business
    </button>
  ) : (
    <Link
      className={btnClass}
      to={`/signup?redirect=${props.location.pathname}`}
    >
      Sign up to start
    </Link>
  );
  if (!window.location.hostname.match('localhost')) {
    ctaElm = (
      <div className="mt-8 flex items-center">
        <div className="bg-primary-300 text-primary-900 h-12 px-6 font-bold   rounded-lg flex items-center">
          Coming soon
        </div>
        <a
          target="_blank"
          className="flex items-center font-medium text-gray-900 hover:underline ml-3 h-12 px-6 rounded"
          href="https://www.facebook.com/groups/loucal"
        >
          <img
            src={process.env.PUBLIC_URL + '/logos/facebook.png'}
            className="h-8 w-8 mr-2"
          />
          Join our group <ChevronRight />
        </a>
      </div>
    );
  }
  const businessId = _.get(props.match, 'params.id');

  return (
    <>
      <Helmet title="Claim your business - Loucal" />

      <React.Suspense fallback={null}>
        <SetupModal
          toggleModal={() => toggleModal(!modal)}
          isOpen={modal}
          businessId={businessId}
        />
      </React.Suspense>
      <div
        style={{
          ...baseContainerStyle,
          backgroundSize: props.isMedium ? 'cover' : 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: props.isMedium ? 'center right' : 'top right',
          backgroundImage: props.isMedium
            ? ''
            : `url(${process.env.PUBLIC_URL + '/lemonade.jpg'})`,
          backgroundColor: '#ffdce0',
        }}
        className="text-gray-900 overflow-hidden relative flex align-center justify-center"
      >
        <div className="flex mx-12 md:mr-32 justify-center max-w-3xl my-24 flex-col relative z-10 items-start">
          <h5 className="uppercase font-semibold text-primary-800 mb-8 tracking-wide">
            Loucal <span className="mr-2">❤️</span>
            <React.Suspense fallback={<>you</>}>
              <Tag id={businessId} />
            </React.Suspense>
          </h5>
          <h1 style={{ lineHeight: '4.5rem' }} className="text-6xl font-bold">
            Join a community of local businesses
          </h1>
          <p className="text-xl mt-6">
            Provide updated pickup/delivery instructions, delivery platform
            links, and more completely for free. Easier than making lemonade.
          </p>
          {ctaElm}
        </div>
      </div>

      <div className=" my-40 text-gray-900 bg-white relative flex align-center justify-center">
        <div className="max-w-5xl mx-12 md:mx-0">
          <div className="flex flex-col items-center">
            <hr className="w-12" />
            <h2 className="text-center text-5xl mt-6 mb-24 font-semibold">
              Claim your business in 3 easy steps
            </h2>
          </div>
          <div className="grid gap-12 grid-rows-3 md:grid-rows-1 md:grid-cols-3">
            <CheckSection
              title="Sign up for an account"
              description="Create a free account using any email address"
            />
            <CheckSection
              title="Verify your claim"
              description="Enter a code that is sent to your businesses phone number"
            />
            <CheckSection
              title="Update your details"
              description="Add specific pickup/delivery instructions and delivery platform links"
            />
          </div>
        </div>
      </div>
      <div className="py-40 px-12 md:px-0  text-gray-900 bg-neutral relative flex align-center justify-center">
        <div className="container md:flex-row flex-col flex">
          <div className="flex flex-col items-center md:mr-24 mr-0 md:items-start">
            <h2 className="md:text-left text-center text-4xl  font-bold">
              Compatible with all popular delivery platforms
            </h2>
            {ctaElm}
          </div>
          <div className="flex justify-center  md:mt-0 mt-24 flex-wrap md:flex-no-wrap items-center">
            <div
              className="bg-contain rounded-lg bg-no-repeat bg-center h-32 w-32 m-2"
              style={{
                minWidth: '8rem',
                backgroundColor: '#fe3008',
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/logos/doordash.png'
                })`,
              }}
            />
            <div
              className="bg-contain rounded-lg bg-no-repeat bg-center h-32 w-32 m-2"
              style={{
                minWidth: '8rem',

                backgroundColor: 'Black',
                backgroundSize: '75% 75%',
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/logos/uber-eats.png'
                })`,
              }}
            />
            <div
              className="bg-contain rounded-lg bg-no-repeat bg-center h-32 w-32 m-2"
              style={{
                minWidth: '8rem',

                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/logos/grubhub.png'
                })`,
              }}
            />
            <div
              className="bg-contain rounded-lg bg-no-repeat bg-center h-32 w-32 m-2"
              style={{
                minWidth: '8rem',

                backgroundImage: `url(${
                  process.env.PUBLIC_URL + '/logos/postmates.png'
                })`,
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
});
