import React, { useRef, useState, useMemo, Suspense, useEffect } from "react";
import {
  Canvas,
  useLoader,
  useFrame,
  useThree,
  extend
} from "react-three-fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import * as THREE from "three";
import ThinFilmFresnelMap from "utils/ThinFilmFresnelMap";
import IridescentMaterial from "utils/IridescentMaterial";
import _ from "lodash";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Physics, usePlane, useBox } from "use-cannon";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Group } from "three";
extend({ OrbitControls });
const spacingX = 7;
const spacingY = 7;
const rows = 12;
const columns = 6;

function random(min, max, decimalPlaces) {
  var rand = Math.random() * (max - min) + min;
  var power = Math.pow(10, decimalPlaces);
  return Math.floor(rand * power) / power;
}

const loadFood = async ({ name, scene, hasBump, hasNormal, scale }) => {
  let mesh;
  const obj = await loadObj(
    process.env.PUBLIC_URL + "/food/" + name + "/model.obj"
  );
  const map = await loadTexture(
    process.env.PUBLIC_URL + "/food/" + name + "/texture.jpeg"
  );
  let bumpMap;
  let normalMap;
  if (hasBump)
    bumpMap = await loadTexture(
      process.env.PUBLIC_URL + "/food/" + name + "/bump.jpeg"
    );
  if (hasNormal)
    normalMap = await loadTexture(
      process.env.PUBLIC_URL + "/food/" + name + "/normal.jpeg"
    );
  let geometry;
  let material = new THREE.MeshStandardMaterial({
    map,
    color: 0xffffff,
    bumpMap,
    normalMap
  });
  obj.traverse(child => {
    if (child instanceof THREE.Object3D) {
      geometry = child.geometry;
    }
  });
  geometry.scale(scale, scale, scale);
  const count = rows * columns;

  mesh = new THREE.InstancedMesh(geometry, material, count);
  mesh.position.set(0, 0, 0);

  mesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);

  mesh.position.set(0, 0, 0);
  var transform = new THREE.Object3D();

  scene.add(mesh);
  return mesh;
};

var gltfLoader = new GLTFLoader();
var objLoader = new OBJLoader();
var texLoader = new THREE.TextureLoader();
const loadTexture = async url => {
  return new Promise(res => {
    texLoader.load(url, res);
  });
};

const loadObj = async url => {
  return new Promise(res => {
    objLoader.load(url, res);
  });
};

const initRotation = _.times(rows * columns, () => random(0, Math.PI, 3));

function Banana(props) {
  const ref = useRef();
  const { gl, scene } = useThree();

  let bananaMesh;
  let bagelMesh;
  let waffleMesh;
  let meatMesh;
  var instanceMatrix = new THREE.Matrix4();
  var matrix = new THREE.Matrix4();
  useFrame(state => {
    if (!waffleMesh || !bagelMesh || !meatMesh) return;
    var transform = new THREE.Object3D();
    const count = rows * columns;

    var offset = (count - 1) / 2;
    let i = 0;
    const time = Date.now() * 0.001;
    for (let x = rows / -2; x < rows / 2; x++) {
      let count = 0;
      for (let y = columns / -2; y < columns / 2; y++) {
        transform.position.set(x * spacingX, y * spacingY, -20);
        transform.position.y += Math.sin(time * 0.5 + x);
        transform.rotation.y = time * 0.25 + Math.PI / 2;
        transform.rotation.x = initRotation[i];
        transform.updateMatrix();
        let selectedMesh;

        switch (count) {
          case 2:
            selectedMesh = meatMesh;
            break;
          case 1:
            selectedMesh = waffleMesh;
            break;
          case 0:
            selectedMesh = bagelMesh;
            break;
        }
        count = count === 2 ? 0 : count + 1;
        selectedMesh.setMatrixAt(i, transform.matrix);
        i++;
      }
    }
    meatMesh.instanceMatrix.needsUpdate = true;
    bagelMesh.instanceMatrix.needsUpdate = true;
    waffleMesh.instanceMatrix.needsUpdate = true;
  });

  async function init() {
    bagelMesh = await loadFood({
      name: "bagel",
      scene,
      hasBump: true,
      hasNormal: true,
      scale: 0.025
    });
    waffleMesh = await loadFood({
      name: "waffle",
      scene,
      hasBump: true,
      hasNormal: true,
      scale: 0.025
    });
    meatMesh = await loadFood({
      name: "meat",
      scene,
      hasBump: false,
      scale: 0.01,
      hasNormal: false
    });
  }

  useEffect(() => {
    init();
  }, []);

  return null;
}

function Controls() {
  const { gl, camera } = useThree();
  const controls = useRef();
  useFrame(state => controls.current.update());
  return <orbitControls ref={controls} args={[camera, gl.domElement]} />;
}

export default () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const aspect = width / height;
  const D = 1;

  return (
    <Canvas camera={[-D * aspect, D * aspect, D, -D, 1, 1000]}>
      <Controls />
      <Suspense fallback={null}>
        <Banana position={[0, 2, 0]} />
      </Suspense>
      <ambientLight intensity={1.2} />
    </Canvas>
  );
};
