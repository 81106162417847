import React, { Suspense } from 'react';
import cx from 'classnames';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import Map from 'components/map';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { useData, useYelp } from 'data';
import PhotoSlider from 'components/photo-slider';
import _ from 'lodash';
import moment from 'moment';

const Header = (props) => {
  const query = localStorage.getItem('query');

  let { business } = props;
  return (
    <div className="container max-w-screen-lg mx-auto pt-8">
      <Link
        style={{ margin: '0 -1rem' }}
        className="hidden md:inline-flex px-2 transition-all duration-200 ease-out-cubic text-gray-400  align-center hover:text-black "
        to={`/s?${query && query !== 'undefined' ? query : ''}`}
      >
        <ChevronLeft className="" />
        Back
      </Link>
      <div className="flex flex-col md:flex-row items-center justify-between">
        {props.loading ? (
          <div className="w-64 h-12 bg-gray-200" />
        ) : (
          <h1 className="text-4xl md:text-left text-center font-semibold my-1">
            {business.name}
          </h1>
        )}
        {!props.loading && !business.user_id && (
          <div className="flex items-center md:flex-row flex-col">
            <p className="text-primary-900">Is this yours?</p>
            <Link
              className="text-md md:my-0 text-primary-600 font-medium mx-3 px-4 py-2 my-4 hover:bg-primary-200 bg-primary-100 rounded-md hover:bg-primary-100"
              to={`/claim-business/${business.id}`}
            >
              Claim {!props.isMedium ? 'business' : ''}
            </Link>
          </div>
        )}
      </div>
      <div className="flex flex-wrap  md:items-start items-center md:justify-start justify-center">
        <div className="flex items-center flex-wrap">
          <div className="text-gray-700 flex align-center justify-center">
            <Star className="h-4 w-4 mt-1 mr-1 fill-current" />
            {props.loading ? (
              <div className="w-6 h-4 mt-1 bg-gray-200" />
            ) : (
              <p className="text-gray-600">
                {business.rating} ({business.review_count})
              </p>
            )}
          </div>
        </div>
        <p className="text-gray-700 mx-2">·</p>
        <div className="text-gray-700 flex flex-col justify-center">
          {props.loading ? (
            <div className="w-6 h-4 mt-1 bg-gray-200" />
          ) : (
            business.price
          )}
        </div>
        {!props.loading &&
          props.yelp.categories &&
          props.yelp.categories.map((cat) => (
            <div className="inline-flex align-center" key={cat.alias}>
              <p className="text-gray-700 mx-2">·</p>
              <p className="text-gray-700">{cat.title}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Header;
