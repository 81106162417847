import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as Close } from 'assets/icons/x.svg';
import classNames from 'classnames';
export default (props) => {
  if (!props.isOpen) return null;
  const size = props.size || 'md';
  const elm = (
    <>
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ background: 'rgba(255, 255, 255, 0.75)' }}
        className="fixed z-50 block overflow-auto md:flex items-center justify-center top-0 left-0 right-0 bottom-0"
      >
        <div
          className={classNames(
            'shadow-2xl w-auto md:w-full  md:m-0 m-8 bg-white py-6 px-10 relative rounded-lg',
            { ['md:max-w-2xl']: size === 'md', ['md:max-w-lg']: size === 'sm' },
          )}
        >
          {props.onClose && (
            <button
              onClick={props.onClose}
              className="absolute top-0 p-6 right-0 mr-2 close-button"
            >
              <Close />
            </button>
          )}
          <div className="modal-guts">
            <h2 className="font-semibold text-2xl">{props.title}</h2>
            <div className="mt-4 ">{props.children}</div>
          </div>
        </div>
      </div>
      <div className="modal-overlay" />
    </>
  );
  return ReactDOM.createPortal(elm, document.body);
};
