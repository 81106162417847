import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as LogoWhite } from 'assets/logo-white.svg';
import { ReactComponent as Smiley } from 'assets/smiley.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
// import { useProfile, useCategories } from 'data';
import UserContext from 'context/UserContext';
import { RowSpacer } from 'components';
import cx from 'classnames';
import _ from 'lodash';
import { withDevice } from 'hoc';
import { ReactComponent as Search } from 'assets/search.svg';
import { ReactComponent as Bookmarks } from 'assets/heart.svg';
import { ReactComponent as Smiley2 } from 'assets/smiley2.svg';

import SearchInput from './search-input';
import './navigation.css';
import Nav from 'routes/business/nav';

const inverse = ['/'];
const fixed = ['/s'];

const Profile = (props) => {
  const profile = props.user;
  try {
    return (
      <Link to="/profile" className="flex items-center md:-mr-4">
        {!props.isMedium && (
          <p
            className={cx(' text-gray-600 h-6 flex items-center pl-6', {
              'mr-4': profile.first_name,
            })}
          >
            {profile.first_name ? `Howdy, ${profile.first_name}` : ''}
          </p>
        )}
        <Smiley2 className="h-8 w-8 text-gray-900 fill-current" />
      </Link>
    );
  } catch (e) {
    if (e instanceof Promise) {
      throw e;
    }
    return null;
  }
};

const Navigation = (props) => {
  const { user } = React.useContext(UserContext);
  if (
    props.location.pathname === '/login' ||
    props.location.pathname === '/signup'
  )
    return null;
  const isInverse = inverse.indexOf(props.location.pathname) > -1;
  const isFixed = fixed.indexOf(props.location.pathname) > -1;
  const query = new URLSearchParams(props.location.search);
  const activeBottom = 'font-bold text-primary-400';
  const bottomNavLink =
    'text-xs flex flex-col items-center font-semibold justify-center flex-1';
  const bottomNavIcon = 'h-5 w-5 mb-1 fill-current';
  const showBottomNav =
    ['/s', '/profile', '/favorites'].indexOf(props.location.pathname) > -1;
  return (
    <>
      {props.isMedium &&
        showBottomNav &&
        ReactDOM.createPortal(
          <nav className="border  text-gray-900 border-b-0 border-l-0 border-r-0 fixed bottom-0 flex bg-white h-16 left-0 right-0 z-30">
            <NavLink
              exact
              activeClassName={activeBottom}
              className={bottomNavLink}
              to="/"
            >
              <Search className={bottomNavIcon} />
              Search
            </NavLink>
            <NavLink
              activeClassName={activeBottom}
              className={bottomNavLink}
              to="/collections"
            >
              <Bookmarks className={bottomNavIcon} />
              Collections
            </NavLink>
            <NavLink
              activeClassName={activeBottom}
              className={bottomNavLink}
              to="/profile"
            >
              <Smiley className={bottomNavIcon} />
              Profile
            </NavLink>
          </nav>,
          document.body,
        )}
      <nav
        className={cx(
          ' flex-shrink-0 w-full flex items-center justify-between flex-wrap sm:px-8  px-6 h-16 sm:h-20',
          {
            'fixed t-0 l-0 r-0 bg-white z-20': isFixed,
            'bg-transparent absolute t-0 l-0 r-0 ': isInverse,
            'bg-white text-gray-700 border border-r-0 border-l-0 border-t-0': !isInverse,
          },
        )}
      >
        <div className="flex-1 flex">
          <Link
            to={`/${props.location.search}`}
            className="flex items-center flex-shrink-0 mr-10"
          >
            <Logo className="h-6 w-20" />
          </Link>
          {isFixed && !props.isMedium ? (
            <React.Suspense fallback={null}>
              <SearchInput />
            </React.Suspense>
          ) : null}
          {/* <NavLink
            exact
            activeClassName="text-gray-900 bg-neutral font-semibold"
            className="hidden md:block  hover:text-gray-900 text-gray-600 px-4 rounded-full py-1"
            to="/claim-business"
          >
            Claim business
          </NavLink> */}
        </div>

        {!props.isMedium && (
          <div className="block justify-end lg:flex lg:items-center lg:w-auto">
            <NavLink
              exact
              activeClassName="text-gray-900 bg-neutral font-semibold"
              className="hidden md:block  hover:text-gray-900 text-gray-600 px-4 rounded-full py-1"
              to="/collections"
            >
              Collections
            </NavLink>
            {user ? (
              <React.Suspense fallback={null}>
                <Profile isMedium={props.isMedium} user={user} />
              </React.Suspense>
            ) : (
              <a
                href="/signup"
                className="bg-primary-500 hover:bg-primary-700 text-white py-2 px-4 rounded text-sm font-semibold"
              >
                Sign up
              </a>
            )}
          </div>
        )}
      </nav>
    </>
  );
};

export default withDevice(withRouter(Navigation));
