import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import cx from 'classnames';
import { ReactComponent as Star } from 'assets/star.svg';
import { ReactComponent as Heart } from 'assets/icons/heart.svg';
import { Rating, } from 'components';
import CollectionsModal from './collections-modal';

const BusinessCard = (props) => {
  const [modal, setModal] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const CardElm = !props.link || hover ? 'div' : Link;
  const setQuery = () => localStorage.setItem('query', props.query);

  const addToCollection = (e) => {
    e.stopPropagation();
    if (!props.auth) {
      props.push('/signup');
    } else {
      setModal(true);
    }
  };

  const imageUrl = props.imageUrl || props.image_url;
  return (
    <CardElm
      to={props.link}
      onClick={setQuery}
      target={props.openNewTab ? '_blank' : ''}
      className={cx({
        'border-t-0': props.isFirst,
        'border border-l-0 py-0 border-r-0 border-gray-200 ': true,
        'hover:border-white transition-all duration-150 ease-out-cubic': !props.loading,
        'cursor-default': props.loading,
      })}
    >
      <React.Suspense fallback={null}>
        {modal && (
          <CollectionsModal
            name={props.name}
            imageUrl={props.imageUrl}
            id={props.id}
            toggleModal={() => setModal(false)}
            isOpen={modal}
            street={props.street}
          />
        )}
      </React.Suspense>
      <div
        style={{
          padding: '2rem 1rem',
          margin: '0 -1rem',
          width: 'calc(100% + 2rem)',
        }}
        className={cx({
          'flex relative transition-all duration-150 ease-out-cubic rounded w-full flex-col md:flex-row': true,
          'hover:bg-gray-100': !props.loading && !props.isMedium,
        })}
      >
        {props.selected && (
          <div className="w-2 block left-0 -ml-6 top-0 h-full bg-gray-400 absolute" />
        )}
        <div
          className="overflow-hidden "
          style={{
            width: props.isMedium ? '100%' : '16rem',
            height: props.isMedium ? '50vw' : '12rem',
          }}
        >
          {props.loading && !props.hideCollection && (
            <div className="flex absolute  text-primary-500 t-0 l-0 bg-gray-300 rounded-full h-10 w-10  items-center justify-center m-4" />
          )}
          {!props.loading && !props.hideCollection && (
            <button
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={addToCollection}
              className="flex absolute hover:bg-primary-500 hover:text-white text-primary-500 t-0 l-0 bg-white shadow-lg rounded-full h-10 w-10  items-center justify-center m-4"
            >
              <Heart className="h-5 w-5 stroke-current " />
            </button>
          )}
          {props.loading && (
            <div className="object-cover h-full w-full bg-gray-200 " />
          )}
          {imageUrl && !props.lodaing && (
            <img
              className="object-cover rounded h-full w-full bg-gray-100 "
              src={props.imageUrl || props.image_url}
              alt="business image"
            />
          )}
          {!imageUrl && (
            <div className="object-cover h-full w-full flex items-center justify-center text-gray-600 font-medium bg-gray-200 ">
              No images
            </div>
          )}
        </div>
        <div className="flex-1 flex align-center flex-col md:px-8 px-0 pt-6 md:py-6">
          <div className="font-bold text-xl mb-1 text-gray-900">
            {props.loading ? (
              <div className="w-64 mb-2 h-6 bg-gray-200" />
            ) : (
                `${props.pageId}. ${props.name}`
              )}
          </div>
          {props.loading ? (
            <div className="w-32 mb-3 h-5 bg-gray-200" />
          ) : (
              <div className="text-m mb-2 text-gray-900">{props.street}</div>
            )}
          {props.loading ? (
            <div className="w-6 h-5 mr-2 bg-gray-200" />
          ) : (
              <Rating rating={props.rating} reviewCount={props.reviewCount} />
            )}
          <div className="text-gray-700 mt-2 text-sm flex-wrap flex text-size-sm">
            {props.loading ? (
              <div className="w-8 h-5 bg-gray-200" />
            ) : (
                props.price
              )}

            {props.loading ? (
              <div className="ml-2 w-12 h-5 bg-gray-200" />
            ) : null}
            {props.categories &&
              typeof props.categories === 'string' &&
              props.categories.split('|').map((cat) => (
                <div className="inline-flex items-center" key={cat}>
                  <p className="text-gray-700 mx-1">·</p>
                  <p className="text-gray-700">{cat}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </CardElm>
  );
};

export default BusinessCard;
