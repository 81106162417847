import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import App from './App';
import * as serviceWorker from './serviceWorker';

Bugsnag.start({
  apiKey: '7c79abf6887b18eff77c5d96ac1b4934',
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: ['production'],
});

const ErrorBoundary = Bugsnag.getPlugin('react');

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
