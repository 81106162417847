import React, { Suspense } from 'react';
import cx from 'classnames';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import Map from 'components/map';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { useData, useYelp } from 'data';
import PhotoSlider from 'components/photo-slider';
import _ from 'lodash';

const Photo = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={props.className + ' soverflow-hidden'}
    >
      <img
        src={props.src}
        className="bg-gray-200 object-cover  h-full w-full"
        alt="image"
      />
    </button>
  );
};

const Photos = (props) => {
  let photos;

  const [toggled, setToggled] = React.useState(-1);

  switch (props.isMedium && props.photos.length > 0 ? 1 : props.photos.length) {
    case 0: {
      photos = (
        <div className="w-full h-full bg-gray-100 text-gray-600 text-xl text-center items-center justify-center flex">
          This business has no photos
        </div>
      );
      break;
    }
    case 1: {
      photos = (
        <Photo
          onClick={() => setToggled(0)}
          src={props.photos[0]}
          className="row-span-4 col-span-3"
        />
      );
      break;
    }
    case 2: {
      photos = (
        <>
          <Photo
            onClick={() => setToggled(0)}
            src={props.photos[0]}
            className="row-span-4 col-span-3 "
          />
          <Photo
            onClick={() => setToggled(1)}
            src={props.photos[1]}
            className="row-span-4 "
          />
        </>
      );
      break;
    }
    case 3: {
      photos = (
        <>
          <Photo
            onClick={() => setToggled(0)}
            src={props.photos[0]}
            className="row-span-4 col-span-3"
          />
          <Photo
            onClick={() => setToggled(1)}
            src={props.photos[1]}
            className="row-span-2"
          />
          <Photo
            src={props.photos[2]}
            onClick={() => setToggled(2)}
            className="row-span-2"
          />
        </>
      );
      break;
    }
    case 4: {
      photos = (
        <>
          <Photo
            onClick={() => setToggled(0)}
            src={props.photos[0]}
            className="row-span-4 col-span-1"
          />
          <Photo
            onClick={() => setToggled(1)}
            src={props.photos[1]}
            className="row-span-4"
          />
          <Photo
            onClick={() => setToggled(2)}
            src={props.photos[2]}
            className="row-span-2"
          />
          <Photo
            onClick={() => setToggled(3)}
            src={props.photos[3]}
            className="row-span-2"
          />
        </>
      );
      break;
    }
    default: {
      photos = (
        <>
          <Photo
            onClick={() => setToggled(0)}
            src={props.photos[0]}
            className="row-span-4 col-span-4"
          />
          <Photo
            onClick={() => setToggled(1)}
            src={props.photos[1]}
            className="row-span-2"
          />
          <Photo
            onClick={() => setToggled(2)}
            src={props.photos[2]}
            className="row-span-2"
          />
          <Photo
            onClick={() => setToggled(3)}
            src={props.photos[3]}
            className="row-span-2"
          />
          <Photo
            onClick={() => setToggled(4)}
            src={props.photos[4]}
            className="row-span-2"
          />
        </>
      );
      break;
    }
  }

  if (props.loading)
    photos = (
      <div className="row-span-4 col-span-4 bg-gray-200 h-full w-full" />
    );
  return (
    <div style={{ maxWidth: '1100px' }} className="container mx-auto pt-4">
      <div
        style={{ height: '28rem' }}
        className={`mt-8 h-64 grid-rows-4 grid-flow-col gap-2 overflow-hidden rounded-lg ${
          props.photos.length ? 'grid' : ''
        }`}
      >
        <PhotoSlider
          onClose={() => setToggled(-1)}
          selected={toggled}
          photos={props.photos}
        />
        {photos}
      </div>
    </div>
  );
};

export default Photos;
