import React, { useContext } from 'react';
import { ReactComponent as Left } from 'assets/icons/arrow-left.svg';
import cx from 'classnames';
import { Link, NavLink, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserContext from 'context/UserContext';
import { Footer, Input, Label, RowSpacer } from 'components';
import { useBusiness } from 'data';
import Categories from 'data/categories.json';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';

const Tab = (props) => (
  <NavLink
    to={props.to}
    className="text-md m-2   rounded-md py-2 px-3  font-medium"
    activeClassName="bg-gray-900 text-white "
  >
    {props.children}
  </NavLink>
);
const Section = (props) => {
  if (props.hidden) return null;
  return (
    <div className="flex flex-col  pt-8 w-full">
      {props.title && (
        <h1 className="text-xl font-semibold mb-6">{props.title}</h1>
      )}
      {props.children}
    </div>
  );
};

const Header = (props) => {
  const { logOut, user } = useContext(UserContext);
  const business = useBusiness(props.id);
  console.log(business);
  return (
    <div>
      <div className=" pb-8 text-gray-900 flex items-center">
        <div className="h-16 mr-6 w-16 overflow-hidden">
          <img
            src={business.image_url}
            className="object-cover rounded h-full w-full bg-gray-100 "
          />
        </div>
        <div>
          <div className="text-xl font-semibold">{business.name}</div>
          <div className="text-md">{business.street}</div>
        </div>
      </div>
    </div>
  );
};

const Details = (props) => {
  const business = useBusiness(props.id);
  const categories = new Map();
  Categories.map((item) => {
    categories.set(`${item.id}`, item.name);
  });

  console.log(categories);
  return (
    <div>
      <Section title="Details">
        <div className="grid  grid-cols-2 gap-6 ">
          <div>
            <Label className="mb-2">Name</Label>
            <RowSpacer size="sm" />
            <Input value={business.name} />
          </div>
          <div>
            <Label>Location</Label>
            <RowSpacer size="sm" />

            <Input value={business.street} />
          </div>
        </div>
        <div className="grid grid-cols-3 pt-6  gap-6 ">
          <div className="flex">
            <input
              checked={business.delivery}
              className="mr-2"
              type="checkbox"
            />

            <Label>Has delivery</Label>
          </div>
          <div>
            <input
              checked={business.has_carryout}
              className="mr-2"
              type="checkbox"
            />

            <Label>Has carryout</Label>
          </div>
          <div>
            <input
              checked={business.has_no_contact}
              className="mr-2"
              type="checkbox"
            />

            <Label>Has no contact delivery</Label>
          </div>
        </div>
      </Section>
      <Section title="Categories">
        <div className="flex -m-2">
          {business.categories.map((category) => (
            <div className="inline-flex m-2 text-sm px-3 py-1 bg-gray-200 rounded">
              {categories.get(`${category.category_id}`)}
            </div>
          ))}
          <button className="ml-2 flex items-center">
            Add
            <Plus className="h-4 ml-2 w-4" />
          </button>
        </div>
      </Section>
    </div>
  );
};
class Edit extends React.PureComponent {
  render() {
    const { props } = this;
    const tab = props.match.params.tab;
    return (
      <>
        <div className="h-screen text-gray-900 flex flex-col bg-neutral h-full flex relative">
          <Helmet>
            <title>Your Profile - Loucal</title>
          </Helmet>
          <div className="flex-col md:mx-auto mx-4 relative z-10 container w-full max-w-screen-md flex align-center justify-start">
            <Link
              className="md:mt-24 mt-12 md:inline-flex self-start hidden p-8  text-gray-900 px-2 -mx-2 opacity-50 hover:opacity-100"
              to={`/business/${props.match.params.id}`}
            >
              <Left />
            </Link>
            <h1 className="md:mt-0 mt-12 font-semibold md:text-3xl text-2xl">
              Edit business
            </h1>
            <div className="mt-6 mb-64 w-full md:w-full bg-white rounded-md p-6 md:p-12 mx-auto flex flex-col align-center">
              <React.Suspense fallback={null}>
                <Header id={props.match.params.id} push={props.history.push} />
              </React.Suspense>
              <div className="text-gray-600 -mx-2 pb-8">
                <Tab to="details">Details</Tab>
                <Tab to={`reviews`}>Reviews</Tab>
                <Tab to="links">Links</Tab>
              </div>
              <hr />
              {tab === 'details' && (
                <React.Suspense fallback={null}>
                  <Details id={props.match.params.id} />
                </React.Suspense>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Edit;
