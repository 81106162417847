import React from 'react';
import { ReactComponent as Logo } from 'assets/logo-b.svg';
import { ReactComponent as Left } from 'assets/icons/arrow-left.svg';
import Food from 'components/3dfood';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { fetchData } from 'data';
import Cookies from 'js-cookie';
import moment from 'moment';
import _ from 'lodash';
import { withContext } from 'hoc';
import UserContext from 'context/UserContext';
import { Input, Label, RowSpacer, Alert, Button } from 'components';

import { validateEmail } from 'utils';

export default withContext(
  UserContext,
  class extends React.PureComponent {
    isLogin = () => this.props.location.pathname.indexOf('login') > -1;

    state = {
      data: {},
      error: '',
      checkEmail: false,
    };

    verifyFields = () => {
      this.setState({ error: '', checkEmail: false });
      const signupState = this.state.data;
      if (!signupState.email || !validateEmail(signupState.email)) {
        this.setState({ error: 'Please enter an email' });
        return false;
      }
      if (
        !signupState.password ||
        (!this.isLogin() && !signupState.password2)
      ) {
        this.setState({ error: 'Please enter a password' });
        return false;
      }

      if (!this.isLogin() && signupState.password !== signupState.password2) {
        this.setState({ error: 'Passwords do not match' });
        return false;
      }
      return true;
    };

    hasRedirect = () => {
      const query = new URLSearchParams(this.props.location.search);
      return query.get('redirect');
    };

    submitLogin = () => {
      if (this.verifyFields()) {
        fetchData('/oauth/login', {
          method: 'POST',
          body: JSON.stringify(
            _.omit(this.state.data, ['password2', 'first_name', 'last_name']),
          ),
        })
          .then((login) => {
            this.handleLogin(login);
            this.props.setAuth(login.access_token);
          })
          .catch((e) => {
            this.setState({ error: e.message });
          });
      }
    };

    submitSignup = () => {
      if (this.verifyFields())
        fetchData('/oauth/signup', {
          method: 'POST',
          body: JSON.stringify(_.omit(this.state.data, 'password2')),
        })
          .then(() => {
            this.setState({ verifyEmail: true });
          })
          .catch((e) => {
            this.setState({ error: e.message });
          });
    };

    handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        if (this.isLogin()) {
          this.submitLogin();
        } else {
          this.submitSignup();
        }
      }
    };

    componentDidMount() {
      window.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
      window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleLogin = (data) => {
      Cookies.set('access_token', data.access_token, {
        path: '',
        expires: moment(data.expires_in).toDate(),
      });
      this.props.context.fetchUser();
      if (this.hasRedirect()) {
        this.props.history.push(this.hasRedirect());
      } else {
        this.props.history.push('/s');
      }
    };

    signUp = async () => {
      if (this.isLogin()) {
        this.submitLogin();
      } else {
        this.submitSignup();
      }
    };

    onChangeSignup = (key, val) =>
      this.setState({
        data: {
          ...this.state.data,
          [key]: val,
        },
      });

    reset = () => this.setState({ error: '', verifyEmail: false });

    render() {
      const { onChangeSignup } = this;
      const isLogin = this.isLogin();
      return (
        <div className="h-screen flex flex-col bg-neutral h-full flex align-center justify-center">
          <div className="flex-1 relative z-10 container h-full mx-auto flex align-center justify-center">
            <div
              style={{ maxWidth: '28rem' }}
              className="w-full bg-white  rounded-md px-12 m-auto flex flex-col align-center py-12"
            >
              <a
                className="fixed top-0 left-0 p-8 ml-8 text-gray-900 px-2 mb-8 -mx-2 opacity-50 hover:opacity-100"
                href="/"
              >
                <Left />
              </a>
              <Logo width="8rem" className="mb-10" />
              <div className="flex">
                <Link
                  onClick={this.reset}
                  to={`/signup${this.props.location.search}`}
                  className={cx({
                    'text-center text-gray-900 mb-6 text-xl font-bold': true,
                    'opacity-25': isLogin,
                  })}
                >
                  Sign up
                </Link>
                <Link
                  onClick={this.reset}
                  to={`/login${this.props.location.search}`}
                  className={cx({
                    'text-center ml-4 text-gray-900 mb-6 text-xl font-bold': true,
                    'opacity-25': !isLogin,
                  })}
                >
                  Login
                </Link>
              </div>
              {!this.state.verifyEmail ? (
                <>
                  {!isLogin && (
                    <>
                      <Label>Name</Label>
                      <div className="flex">
                        <Input
                          placeholder="First"
                          onChange={this.onChangeSignup}
                          name="first_name"
                        />
                        <div className="w-8" />
                        <Input
                          placeholder="Last"
                          onChange={this.onChangeSignup}
                          name="last_name"
                        />
                      </div>
                    </>
                  )}
                  <RowSpacer size="md" />
                  <Label>Email</Label>
                  <Input
                    placeholder="Email"
                    type="email"
                    required={true}
                    onChange={this.onChangeSignup}
                    name="email"
                    className="bg-gray-200 mx-auto p-2 rounded-lg w-full"
                  />
                  <RowSpacer size="md" />
                  <Label>Password</Label>
                  <Input
                    placeholder="Password"
                    type="password"
                    required={true}
                    onChange={this.onChangeSignup}
                    name="password"
                  />
                  {!isLogin && (
                    <>
                      <RowSpacer size="md" />
                      <Label>Password again</Label>
                      <Input
                        placeholder="Password again"
                        type="password"
                        required={true}
                        onChange={this.onChangeSignup}
                        name="password2"
                      />
                    </>
                  )}
                  {this.state.error && (
                    <Alert className="mt-12" type="error">
                      {this.state.error}
                    </Alert>
                  )}
                  <Button className="mt-8" onClick={this.signUp}>
                    {this.isLogin() ? 'Login' : 'Sign up'}
                  </Button>
                </>
              ) : (
                <Alert type="success">
                  <p className="font-semibold">Sign up successful</p>
                  <p>Check your email to verify the account</p>
                </Alert>
              )}
            </div>
          </div>
          <div className="flex-1  fixed left-0 top-0 right-0 bottom-0"></div>
        </div>
      );
    }
  },
);
