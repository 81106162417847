import React from 'react';
import { ReactComponent as Star } from 'assets/star.svg';
import { ReactComponent as Half } from 'assets/star-half.svg';
import _ from 'lodash';
export default (props) => {
  const c = 'h-4 w-4 current-fill';
  return (
    <div className={'flex items-center text-primary-500 ' + props.className}>
      {_.times(Math.floor(props.rating), (x) => (
        <Star
          key={'star-' + x}
          style={{ marginRight: '.15rem' }}
          className={c}
        />
      ))}
      {Math.ceil(props.rating) - props.rating <= 0.5 &&
      Math.ceil(props.rating) - props.rating !== 0 &&
      Math.ceil(props.rating) - props.rating !== 1 ? (
        <Half style={{ marginLeft: '-.15rem' }} className={c} />
      ) : null}
      {props.reviewCount ? (
        <p className="text-gray-600 text-xs pl-1">
          {props.reviewCount} review{props.reviewCount === 1 ? '' : 's'}
        </p>
      ) : null}
    </div>
  );
};
