import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ReactComponent as Logo } from 'assets/logo-white.svg';
import _ from 'lodash';
import { withDevice } from 'hoc';

export default withDevice((props) => (
  <footer className="w-full py-40 px-12 md:px-0  text-gray-900 bg-black relative flex align-center justify-center">
    <div
      style={{
        gridTemplateColumns: props.isMedium ? 'auto' : '2fr 1fr 1fr',
        gridTemplateRows: props.isMedium ? 'auto auto' : 'auto',
      }}
      className="max-w-5xl gap-12 w-full grid  text-white"
    >
      <div className="flex justify-center items-start  flex-1 flex-col text-white">
        <Logo className="fill-current w-24" />
        <div className="opacity-50 pt-1 my-3">
          Artisanally crafted in Louisville, KY
        </div>
        <a
          className=" bg-gray-900 px-3 text-center py-1 rounded hover:opacity-100 opacity-50"
          href="https://www.facebook.com/groups/144293743640349"
        >
          Join our Facebook group
        </a>
      </div>
      <div className="justify-center items-start flex flex-col ">
        <Link
          className="hover:opacity-100 my-1 hover:underline opacity-50"
          to="/s"
        >
          Search
        </Link>
        <Link
          className="hover:opacity-100 my-1 hover:underline opacity-50"
          to="/about-us"
        >
          About us
        </Link>
      </div>
      <div className="justify-center items-start flex flex-col ">
        <a
          className="hover:opacity-100 my-1 hover:underline opacity-50"
          href="mailto:admin@loucal.us"
        >
          Contact us
        </a>
        <Link
          to="/privacy-policy"
          className="hover:opacity-100 my-1 hover:underline opacity-50"
        >
          Privacy policy
        </Link>
      </div>
    </div>
  </footer>
));
