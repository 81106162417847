import React from 'react';
import cx from 'classnames';

//      <button type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out">

const Button = (props) => (
  <span className="hidden sm:block shadow-sm rounded-md">
    <button
      {...props}
      className={cx(
        props.className +
          ' button focus:outline-none focus:shadow-outline focus:border-blue-300 inline-flex justify-center w-full rounded-md text-base leading-6 font-medium transition ease-out-cubic duration-150 sm:text-sm sm:leading-5',
        {
          'px-4 py-2': !props.size || props.size === 'md',
          'active:text-gray-800 active:bg-gray-50 active:text-gray-800 text-gray-900 hover:text-gray-600  border border-gray-300':
            props.type === 'ghost',
          'text-white hover:bg-primary-600 border border-transparent bg-primary-500 ':
            !props.type || props.type === 'primary',
        },
      )}
    >
      {props.children}
    </button>
  </span>
);

export default Button;
