import React from 'react';
import { Footer } from 'components';
import { ReactComponent as Scribble } from 'assets/scribbles/84.svg';

const About = (props) => {
  return (
    <div className="flex text-gray-900 items-center flex-col">
      <div
        style={{
          backgroundImage: `url('/store.jpeg')`,
        }}
        className="bg-center lg:px-0 px-6 w-full  bg-secondary relative"
      >
        <div className="relative z-10 mx-auto max-w-4xl flex h-full py-24 md:py-48">
          <div className="md:pb-0 pb-12  flex z-10 flex-col  items-start justify-center">
            <h1
              style={{ lineHeight: '3.75rem' }}
              className=" relative z-10 text-5xl text-white text-center font-thin"
            >
              Our mission is to help local restaurants be
              <span className="ml-4 inline-flex items-center  flex-col justify-start relative font-semibold">
                successful.
                <Scribble
                  style={{
                    transform: ' rotate(24deg)',
                  }}
                  className="fill-current text-primary-600 absolute h-48 "
                />
              </span>
            </h1>
          </div>
        </div>
        <div className="absolute bg-black opacity-75 top-0 left-0 r-0 b-0 block w-full h-full" />
      </div>
      <div className="bg-center lg:px-0 py-32  max-w-2xl  text-center px-6 md:w-auto  relative">
        <div className="text-2xl mt-4">Loucal ❤️ Small Businesses</div>
        <hr className="w-32 my-6 mx-auto" />
        <div className="text-gray-700">
          We believe restaurants are at their best when they focus their efforts
          on the quality of their food and service. Whether it be in the kitchen
          preparing for dinner or at the front door greeting customers, these
          activities are what make a restaurant. Said another way, restaurants
          excel when they’re working offline.
          <br />
          <br />
          Loucal was created to bridge this disconnect. Our products help
          restaurants get online quickly and easily so they can maintain direct
          relationships with their customers. As the world evolves and new
          technology platforms emerge, we will be there to help our restaurant
          clients stay up to speed online. We cannot control the taste of their
          food or the friendliness of their staff, but we can arm them with the
          tools needed to compete in the tech-driven world we all live in.
        </div>
      </div>
      <div className="bg-primary-200 text-primary-900 w-full text-center py-12 text-2xl">
        For media inquiries
        <a
          className="text-primary-600 hover:text-primary-700 ml-4"
          href="mailto:admin@loucal.us"
        >
          admin@loucal.us
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default About;
