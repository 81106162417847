import React, { useState, useEffect } from 'react';

import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import { mapboxReverseGeocode } from 'data';
import _ from 'lodash';

const defaultb = [
  [-85.57107327826705, 38.41373559322193],
  [-85.92849021197264, 38.04638412023681],
];

const token =
  'pk.eyJ1IjoibGlvbmdhdGUyIiwiYSI6ImNrODF3cTNnbTBmeDIzZXFxdTQwNnEzN3MifQ.F8UutDpmKuRofW6QVZD3Jw';
const Map = ReactMapboxGl({
  accessToken: token,
});

const MapNonInteractive = ReactMapboxGl({
  accessToken: token,
  scrollZoom: false,
  animate: false,
});
export default (props) => {
  const ref = React.useRef();
  const [fitBounds, setBounds] = useState(props.fitBounds);

  useEffect(() => {}, [ref.current]);

  // useEffect(() => {
  //   if (props.center)
  //     setViewport({
  //       ...viewport,
  //       latitude: props.center.latitude,
  //       longitude: props.center.longitude
  //     });
  // }, [props.center]);
  const [center, setCenter] = React.useState([-85.7585, 38.2527]);

  const onMove = (map) => {
    setBounds(null);
    const c = map.getCenter();

    if (props.onMove) {
      setCenter([c.lng, c.lat]);
      props.onMove(map);
    }
  };
  const Component = props.interactive ? Map : MapNonInteractive;
  return (
    <Component
      style="mapbox://styles/liongate2/ck82ibw8k1yu21insexjuac8d"
      fitBounds={fitBounds}
      className={props.className}
      fitBoundsOptions={{ duration: 0 }}
      center={props.center || center}
      onStyleLoad={props.onStyleLoad}
      onMove={onMove}
      zoom={props.zoom}
      containerStyle={{
        height: '100%',
        width: '100%',
        ...props.containerStyle,
      }}
    >
      {props.children}
    </Component>
  );
};
