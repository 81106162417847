import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ReactComponent as One } from 'assets/illustrations/404/1.svg';
import { ReactComponent as Two } from 'assets/illustrations/404/2.svg';
import { ReactComponent as Three } from 'assets/illustrations/404/3.svg';
import { ReactComponent as Four } from 'assets/illustrations/404/4.svg';
import { ReactComponent as Five } from 'assets/illustrations/404/5.svg';
import { ReactComponent as Six } from 'assets/illustrations/404/6.svg';
import { ReactComponent as Seven } from 'assets/illustrations/404/7.svg';

const illustrations = [One, Two, Three, Four, Five, Six, Seven];
export default withRouter((props) => {
  let Random = illustrations[Math.floor(Math.random() * illustrations.length)];

  return (
    <div className="w-full h-full bg-neutral">
      <div className="text-gray-900 h-full m-auto flex flex-col items-center justify-center container">
        <Random style={{ transform: 'scale(1.5)' }} />
        <h1 className="text-4xl mt-10 text-center font-bold">
          Whoops, that page wasn't found!
        </h1>
        <button
          className="flex mt-8 justify-center items-center text-center bg-secondary  text-white py-2 px-6  rounded text-lg font-semibold"
          onClick={props.history.goBack}
        >
          Go back
        </button>
      </div>
    </div>
  );
});
